import { HashRouter, Route, Routes } from "react-router-dom";
import { HomeContainer } from "./container/Home";
import { GenderContainer } from "./container/Gender";
import { LocationContainer } from "./container/Location";
import { FeelingsContainer } from "./container/Feelings";
import { EndPageContainer } from "./container/EndPage/EndPage";
import { ExperienceContainer } from "./container/EndPage/Experience";
import { ConditionContainer } from "./container/EndPage/Condition";
import { DiscoveryContainer } from "./container/EndPage/Discovery";
import { ThankYouContainer } from "./container/EndPage/ThankYou";
import { FeedbackContainer } from "./container/Feedback/Feedback";
import { MeditationFeedbackContainer } from "./container/Feedback/MeditationFeedback";
import { SleepStoryFeedbackContainer } from "./container/Feedback/SleepStoryFeedback";
import { GameFeedbackContainer } from "./container/Feedback/GameFeedback";
import { ContentContainer } from "./container/Content";
import { ApiClientContext } from "./context/ApiClientContext";
import { createApiClient } from "./common/ApiClient";
import { RegisterUserContextProvider } from "./common/RegisterUserContext";
import { AgeContainer } from "./container/Age";
import { REACT_APP_API_HOST_IP, REACT_APP_IS_GAME_CODE } from "./env";
import { ContentContextProvider } from "./common/ContentData";
import { NameContainer } from "./container/Name";
import { FeedbackIFrameContainer } from "./container/Feedback/FeedbackIFrame";

function App() {
  const apiClient = createApiClient(REACT_APP_API_HOST_IP!);

  const isQwltApp = !REACT_APP_IS_GAME_CODE;

  return (
    <HashRouter>
      <ApiClientContext.Provider value={apiClient}>
        <RegisterUserContextProvider>
          <ContentContextProvider>
            <Routes>
              <Route path="/home" element={<HomeContainer />} />
              <Route path="/age" element={<AgeContainer />} />
              <Route path="/name" element={<NameContainer />} />
              <Route path="/gender" element={<GenderContainer />} />
              <Route path="/location" element={<LocationContainer />} />
              <Route path="/feelings" element={<FeelingsContainer />} />
              <Route path="/endpage" element={<EndPageContainer />} />
              <Route path="/experience" element={<ExperienceContainer />} />
              <Route path="/condition" element={<ConditionContainer />} />
              <Route path="/discovery" element={<DiscoveryContainer />} />
              <Route path="/thankyou" element={<ThankYouContainer />} />
              <Route path="/" element={<HomeContainer />} />
              <Route path={"/feedback"} element={<FeedbackIFrameContainer />} />
              <Route
                path="/meditation-feedback"
                element={<MeditationFeedbackContainer />}
              />
              <Route
                path="/story-feedback"
                element={<SleepStoryFeedbackContainer />}
              />
              <Route
                path="/game-feedback"
                element={<GameFeedbackContainer />}
              />
              <Route path="/content" element={<ContentContainer />} />
            </Routes>
          </ContentContextProvider>
        </RegisterUserContextProvider>
      </ApiClientContext.Provider>
    </HashRouter>
  );
}

export default App;
