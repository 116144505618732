export const registerUserApiPath = '/api/web-page/register-user'

export type RegisterUserResponseDTO = {
  status: number;
  message: string;
  data: any
};

export type RegisterUserRequestDTO = {
  age: number;
  name: string;
  gender: string;
  location: string;
  happyScale: number;
  calmScale: number;
  energizedScale: number;
};
