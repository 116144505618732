import { useNavigate } from "react-router-dom";
import { EndPageComponent } from "../../components/EndPage/Endpage";
import { useEffect, useState } from "react";
import { localStorageLiterals } from "../../Constants";

export const EndPageContainer: React.FC = () => {
  const [isNewContent, setIsNewConent] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const storedNoContent = localStorage.getItem(
      localStorageLiterals.noContent
    );
    if (storedNoContent) {
      setIsNewConent(storedNoContent === "false" ? false : true);
    }
  }, []);

  const handleNextButtonClick = () => {
    navigate("/experience");
  };

  return (
    <EndPageComponent
      isNewContent={isNewContent}
      handleNextButtonClick={handleNextButtonClick}
    />
  );
};
