import { useContext, useEffect, useState } from "react";
import { GenderComponent } from "../components/Gender";
import { useNavigate } from "react-router-dom";
import {
  RegisterUserContext,
  RegisterUserContextProps,
} from "../common/RegisterUserContext";

export const GenderContainer: React.FC = () => {
  const navigate = useNavigate();
  const [enableContinueButton, setEnableContinueButton] = useState(false);
  const [selectedRange, setSelectedRange] = useState<number | number[]>(3);

  const { registerUserDetails, setRegisterUserDetails } = useContext(
    RegisterUserContext
  ) as RegisterUserContextProps;

  const onGenderChange = (value: string) => {
    const uppercaseValue = value.toUpperCase();
    setRegisterUserDetails({ ...registerUserDetails, gender: uppercaseValue });
  };

  const handleBackClick = () => {
    navigate("/age");
  };
  const handleContinueClick = () => {
    navigate("/location");
  };
  useEffect(() => {
    if (registerUserDetails.gender) {
      setEnableContinueButton(true);
    } else {
      setEnableContinueButton(false);
    }
  }, [registerUserDetails.gender]);

  const formatGender = (value: string) => {
    if (!value) return "";
    const lowercaseValue = value.toLowerCase();
    return lowercaseValue.charAt(0).toUpperCase() + lowercaseValue.slice(1);
  };

  return (
    <GenderComponent
      enableContinueButton={enableContinueButton}
      gender={formatGender(registerUserDetails.gender)}
      selectedRange={+selectedRange}
      handleRangeClick={setSelectedRange}
      onGenderChange={onGenderChange}
      handleBackClick={handleBackClick}
      handleContinueClick={handleContinueClick}
      genderOptions={["Male", "Female", "Non-binary", "Other"]}
    />
  );
};
