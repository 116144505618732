import {
  ExperienceOption,
  FeedbackComponent,
  Smiley,
} from "../../components/Feedback/Feedback";
import { useContext, useEffect, useState } from "react";
import { localStorageLiterals } from "../../Constants";
import { GameFeedbackComponent } from "../../components/Feedback/GameFeedback";
import { ApiClientContext } from "../../context/ApiClientContext";
import {
  SubmitFeedbackRequestDTO,
  SubmitFeedbackResponseDTO,
  submitFeedbackApiPath,
} from "../../dto/submitFeedback";
import { useAsync, useAsyncEffect } from "../../hooks/use-async";
import { AxiosResponse } from "axios";
import { REACT_APP_IS_GAME_CODE } from "../../env";
import { ContentResponseDTO, contentApiPath } from "../../dto/content";
import { useNavigate } from "react-router-dom";

const happyExperienceOptions: ExperienceOption[] = [
  { value: "fun", label: "Fun / Entertaining" },
  { value: "other", label: "Other" },
];

const sadExperienceOptions: ExperienceOption[] = [
  { value: "boring", label: "Boring" },
  { value: "frustrating", label: "Frustrating" },
  { value: "confusing", label: "Confusing" },
  { value: "others", label: "Other" },
];

// TODO: move to utils

interface QueryParams {
  [key: string]: string;
}

export function parseQueryString(): QueryParams {
  const queryString = window.location.search.substring(1);
  const params: QueryParams = {};
  queryString.split("&").forEach((pair) => {
    const [key, value] = pair.split("=");
    params[key] = decodeURIComponent(value);
  });
  return params;
}

function parseQueryLocalString(): QueryParams {
  const hashString = window.location.hash;
  const urlParams = new URLSearchParams(hashString);
  const params: QueryParams = {};

  const userIdMatch = hashString.match(/userId=([^&]+)/);
  if (userIdMatch) {
    params["userId"] = decodeURIComponent(userIdMatch[1]);
  }

  urlParams.forEach((value, key) => {
    if (key !== "userId") {
      params[key] = decodeURIComponent(value);
    }
  });

  return params;
}

export const FeedbackIFrameContainer: React.FC = () => {
  const navigate = useNavigate();
  const queryParams = REACT_APP_IS_GAME_CODE
    ? parseQueryString()
    : parseQueryLocalString();
  const playerUserId = queryParams["userId"];
  const playerContentId = queryParams["contentId"];

  const [experience, setExperience] = useState<string[]>([]);
  const [experienceFeedback, setExperienceFeedback] = useState<string>("");
  const [isContinue, setIsContinue] = useState(false);

  const apiClient = useContext(ApiClientContext)!;
  const [selectedRange, setSelectedRange] = useState<number | number[]>(0);
  const [selectedBoredEngagedRange, setBoredEngagedSelectedRange] = useState<
    number | number[]
  >(0);
  const [selectedFocusedScaleRange, setFocusedScaleSelectedRange] = useState<
    number | number[]
  >(0);
  const [selectedAlertScaleRange, setAlertScaleSelectedRange] = useState<
    number | number[]
  >(0);
  const [selectedFrustratedCalmRange, setFrustratedCalmSelectedRange] =
    useState<number | number[]>(0);

  const [selectedRestlessRange, setSelectedRestlessRange] = useState<
    number | number[]
  >(0);

  const [isRangeClicked, setIsRangeClicked] = useState(false);
  const [isFocusedScaleRangeClicked, setIsFocusedScaleRangeClicked] =
    useState(false);
  const [isAlertScaleRangeClicked, setIsAlertScaleRangeClicked] =
    useState(false);
  const [isBoredEngagedRangeClicked, setIsBoredEngagedRangeClicked] =
    useState(false);
  const [isFrustratedCalmRangeClicked, setIsFrustratedCalmRangeClicked] =
    useState(false);
  const [isRestlessRangeClicked, setIsRestlessRangeClicked] = useState(false);
  const [isLast, setIsLast] = useState(false);
  const [enableGameContinueButton, setEnableGameContinueButton] =
    useState(false);
  const [isSubmitClick, setIsSubmitClick] = useState(false);

  const [enableContinueButton, setEnableContinueButton] =
    useState<boolean>(false);
  const [isSmileyClicked, setIsSmileyClicked] = useState<Smiley>({
    isHappySmileyClicked: false,
    isSadSmileyClicked: false,
  });
  const [contentData, setContentData] = useState<any[]>([]);
  const [dataIndex, setDataIndex] = useState<number>(0);

  const [scales, setScales] = useState<any[]>([]);
  const [thoughts, setThoughts] = useState("");

  useEffect(() => {
    if (
      selectedBoredEngagedRange ||
      selectedFrustratedCalmRange ||
      selectedRange
    ) {
      setEnableGameContinueButton(true);
    }
  }, [selectedBoredEngagedRange, selectedFrustratedCalmRange, selectedRange]);

  useEffect(() => {
    const feedback = JSON.parse(
      localStorage.getItem(localStorageLiterals.submitFeedback) ?? "null"
    );
    const storedDataIndex = localStorage.getItem(
      localStorageLiterals.dataIndex
    );

    if (storedDataIndex) {
      setDataIndex(parseInt(storedDataIndex, 10));
    }
    const storedContentData = localStorage.getItem(
      localStorageLiterals.contentData
    );

    if (storedContentData) {
      setContentData(JSON.parse(storedContentData));
    }

    if (feedback && typeof feedback === "object") {
      const { like, comment, reaction } = feedback;
      setIsSmileyClicked({
        isHappySmileyClicked: like === true ? true : false,
        isSadSmileyClicked: like === false ? true : false,
      });
      setExperienceFeedback(comment);
      if (reaction) {
        setExperience(reaction.split(",").map((item: string) => item.trim()));
      }
    }
  }, []);

  const handleHappyClick = () => {
    setIsSmileyClicked({
      isHappySmileyClicked: !isSmileyClicked.isHappySmileyClicked,
      isSadSmileyClicked: false,
    });
    setExperience([]);
    setExperienceFeedback("");
  };

  const handleSadClick = () => {
    setIsSmileyClicked({
      isHappySmileyClicked: false,
      isSadSmileyClicked: !isSmileyClicked.isSadSmileyClicked,
    });
    setExperience([]);
    setExperienceFeedback("");
  };

  const handleContinueClick = () => {
    setIsContinue(true);
  };

  useEffect(() => {
    if (
      experience &&
      (experience.includes("other") || experience.includes("others")) &&
      experienceFeedback
    ) {
      setEnableContinueButton(true);
    } else if (
      experience.length > 0 &&
      !experience.includes("other") &&
      !experience.includes("others")
    ) {
      setEnableContinueButton(true);
    } else {
      setEnableContinueButton(false);
    }
  }, [experience, experienceFeedback]);

  const handleExperienceChange = (value: string[]) => {
    setExperience(value);
  };

  const [contentResult, setContentResult] = useAsyncEffect<ContentResponseDTO>({
    fn: async () => {
      if (playerContentId) {
        const result = await apiClient.get(
          `${contentApiPath}?contentId=${playerContentId}`
        );
        return result.data as any;
      }
    },
    dependencies: [],
  });

  useEffect(() => {
    (async () => {
      if (playerContentId) {
        setContentResult();
      }
    })();
  }, [playerContentId]);

  useEffect(() => {
    if (contentResult.result && contentResult.result.data) {
      const data = contentResult.result.data;
      const scaleNames = data.scales.map((value: any) => value.scaleName);
      setScales(scaleNames);
    }
  }, [contentResult.result]);

  const [enableContinue, setEnableContinue] = useState(false);

  useEffect(() => {
    const isHappyScalePositive =
      (scales.includes("happyScale") || scales.length === 0) && isRangeClicked;
    const isCalmScalePositive =
      scales.includes("calmScale") && isBoredEngagedRangeClicked;
    const isEnergizedScalePositive =
      scales.includes("energizedScale") && isFrustratedCalmRangeClicked;
    const isFocusedScale =
      scales.includes("focussedScale") && isFocusedScaleRangeClicked;
    const isAlertScale =
      scales.includes("alertScale") && isAlertScaleRangeClicked;
    const isRestlessScale =
      scales.includes("restlessScale") && isRestlessRangeClicked;

    let enableContinue = false;

    if (scales.length === 0) {
      enableContinue = isHappyScalePositive;
    } else if (
      scales.includes("happyScale") &&
      scales.includes("calmScale") &&
      scales.includes("energizedScale") &&
      scales.includes("focussedScale") &&
      scales.includes("alertScale") &&
      scales.includes("restlessScale")
    ) {
      enableContinue =
        isHappyScalePositive &&
        isCalmScalePositive &&
        isEnergizedScalePositive &&
        isFocusedScale &&
        isAlertScale &&
        isRestlessScale;
    } else if (
      scales.includes("happyScale") &&
      scales.includes("calmScale") &&
      scales.includes("energizedScale") &&
      scales.includes("focussedScale") &&
      scales.includes("restlessScale")
    ) {
      enableContinue =
        isHappyScalePositive &&
        isCalmScalePositive &&
        isEnergizedScalePositive &&
        isFocusedScale &&
        isRestlessScale;
    } else if (
      scales.includes("happyScale") &&
      scales.includes("calmScale") &&
      scales.includes("energizedScale") &&
      scales.includes("alertScale") &&
      scales.includes("restlessScale")
    ) {
      enableContinue =
        isHappyScalePositive &&
        isCalmScalePositive &&
        isEnergizedScalePositive &&
        isAlertScale &&
        isRestlessScale;
    } else if (
      scales.includes("happyScale") &&
      scales.includes("calmScale") &&
      scales.includes("focussedScale") &&
      scales.includes("alertScale") &&
      scales.includes("restlessScale")
    ) {
      enableContinue =
        isHappyScalePositive &&
        isCalmScalePositive &&
        isFocusedScale &&
        isAlertScale &&
        isRestlessScale;
    } else if (
      scales.includes("happyScale") &&
      scales.includes("energizedScale") &&
      scales.includes("focussedScale") &&
      scales.includes("alertScale") &&
      scales.includes("restlessScale")
    ) {
      enableContinue =
        isHappyScalePositive &&
        isEnergizedScalePositive &&
        isFocusedScale &&
        isAlertScale &&
        isRestlessScale;
    } else if (
      scales.includes("calmScale") &&
      scales.includes("energizedScale") &&
      scales.includes("focussedScale") &&
      scales.includes("alertScale") &&
      scales.includes("restlessScale")
    ) {
      enableContinue =
        isCalmScalePositive &&
        isEnergizedScalePositive &&
        isFocusedScale &&
        isAlertScale &&
        isRestlessScale;
    } else if (
      scales.includes("happyScale") &&
      scales.includes("calmScale") &&
      scales.includes("energizedScale") &&
      scales.includes("focussedScale") &&
      scales.includes("alertScale")
    ) {
      enableContinue =
        isHappyScalePositive &&
        isCalmScalePositive &&
        isEnergizedScalePositive &&
        isFocusedScale &&
        isAlertScale;
    } else if (
      scales.includes("happyScale") &&
      scales.includes("calmScale") &&
      scales.includes("energizedScale") &&
      scales.includes("restlessScale")
    ) {
      enableContinue =
        isHappyScalePositive &&
        isCalmScalePositive &&
        isEnergizedScalePositive &&
        isRestlessScale;
    } else if (
      scales.includes("happyScale") &&
      scales.includes("energizedScale") &&
      scales.includes("focussedScale") &&
      scales.includes("restlessScale")
    ) {
      enableContinue =
        isHappyScalePositive &&
        isEnergizedScalePositive &&
        isFocusedScale &&
        isRestlessScale;
    } else if (
      scales.includes("happyScale") &&
      scales.includes("energizedScale") &&
      scales.includes("alertScale") &&
      scales.includes("restlessScale")
    ) {
      enableContinue =
        isHappyScalePositive &&
        isEnergizedScalePositive &&
        isAlertScale &&
        isRestlessScale;
    } else if (
      scales.includes("happyScale") &&
      scales.includes("calmScale") &&
      scales.includes("focussedScale") &&
      scales.includes("restlessScale")
    ) {
      enableContinue =
        isHappyScalePositive &&
        isCalmScalePositive &&
        isFocusedScale &&
        isRestlessScale;
    } else if (
      scales.includes("happyScale") &&
      scales.includes("calmScale") &&
      scales.includes("alertScale") &&
      scales.includes("restlessScale")
    ) {
      enableContinue =
        isHappyScalePositive &&
        isCalmScalePositive &&
        isAlertScale &&
        isRestlessScale;
    } else if (
      scales.includes("happyScale") &&
      scales.includes("focussedScale") &&
      scales.includes("alertScale") &&
      scales.includes("restlessScale")
    ) {
      enableContinue =
        isHappyScalePositive &&
        isFocusedScale &&
        isAlertScale &&
        isRestlessScale;
    } else if (
      scales.includes("calmScale") &&
      scales.includes("energizedScale") &&
      scales.includes("focussedScale") &&
      scales.includes("alertScale") &&
      scales.includes("restlessScale")
    ) {
      enableContinue =
        isCalmScalePositive &&
        isEnergizedScalePositive &&
        isFocusedScale &&
        isAlertScale &&
        isRestlessScale;
    } else if (
      scales.includes("calmScale") &&
      scales.includes("energizedScale") &&
      scales.includes("alertScale") &&
      scales.includes("restlessScale")
    ) {
      enableContinue =
        isCalmScalePositive &&
        isEnergizedScalePositive &&
        isAlertScale &&
        isRestlessScale;
    } else if (
      scales.includes("energizedScale") &&
      scales.includes("focussedScale") &&
      scales.includes("alertScale") &&
      scales.includes("restlessScale")
    ) {
      enableContinue =
        isEnergizedScalePositive &&
        isFocusedScale &&
        isAlertScale &&
        isRestlessScale;
    } else if (
      scales.includes("calmScale") &&
      scales.includes("focussedScale") &&
      scales.includes("alertScale") &&
      scales.includes("restlessScale")
    ) {
      enableContinue =
        isCalmScalePositive &&
        isFocusedScale &&
        isAlertScale &&
        isRestlessScale;
    } else if (
      scales.includes("happyScale") &&
      scales.includes("calmScale") &&
      scales.includes("energizedScale") &&
      scales.includes("focussedScale")
    ) {
      enableContinue =
        isHappyScalePositive &&
        isCalmScalePositive &&
        isEnergizedScalePositive &&
        isFocusedScale;
    } else if (
      scales.includes("happyScale") &&
      scales.includes("calmScale") &&
      scales.includes("energizedScale") &&
      scales.includes("alertScale")
    ) {
      enableContinue =
        isHappyScalePositive &&
        isCalmScalePositive &&
        isEnergizedScalePositive &&
        isAlertScale;
    } else if (
      scales.includes("happyScale") &&
      scales.includes("calmScale") &&
      scales.includes("focussedScale") &&
      scales.includes("alertScale")
    ) {
      enableContinue =
        isHappyScalePositive &&
        isCalmScalePositive &&
        isFocusedScale &&
        isAlertScale;
    } else if (
      scales.includes("happyScale") &&
      scales.includes("energizedScale") &&
      scales.includes("focussedScale") &&
      scales.includes("alertScale")
    ) {
      enableContinue =
        isHappyScalePositive &&
        isEnergizedScalePositive &&
        isFocusedScale &&
        isAlertScale;
    } else if (
      scales.includes("calmScale") &&
      scales.includes("energizedScale") &&
      scales.includes("focussedScale") &&
      scales.includes("alertScale")
    ) {
      enableContinue =
        isCalmScalePositive &&
        isEnergizedScalePositive &&
        isFocusedScale &&
        isAlertScale;
    } else if (
      scales.includes("happyScale") &&
      scales.includes("calmScale") &&
      scales.includes("energizedScale")
    ) {
      enableContinue =
        isHappyScalePositive && isCalmScalePositive && isEnergizedScalePositive;
    } else if (
      scales.includes("happyScale") &&
      scales.includes("energizedScale") &&
      scales.includes("restlessScale")
    ) {
      enableContinue =
        isHappyScalePositive && isEnergizedScalePositive && isRestlessScale;
    } else if (
      scales.includes("happyScale") &&
      scales.includes("calmScale") &&
      scales.includes("restlessScale")
    ) {
      enableContinue =
        isHappyScalePositive && isCalmScalePositive && isRestlessScale;
    } else if (
      scales.includes("happyScale") &&
      scales.includes("focussedScale") &&
      scales.includes("restlessScale")
    ) {
      enableContinue =
        isHappyScalePositive && isFocusedScale && isRestlessScale;
    } else if (
      scales.includes("happyScale") &&
      scales.includes("alertScale") &&
      scales.includes("restlessScale")
    ) {
      enableContinue = isHappyScalePositive && isAlertScale && isRestlessScale;
    } else if (
      scales.includes("calmScale") &&
      scales.includes("energizedScale") &&
      scales.includes("restlessScale")
    ) {
      enableContinue =
        isCalmScalePositive && isEnergizedScalePositive && isRestlessScale;
    } else if (
      scales.includes("energizedScale") &&
      scales.includes("focussedScale") &&
      scales.includes("restlessScale")
    ) {
      enableContinue =
        isEnergizedScalePositive && isFocusedScale && isRestlessScale;
    } else if (
      scales.includes("energizedScale") &&
      scales.includes("alertScale") &&
      scales.includes("restlessScale")
    ) {
      enableContinue =
        isEnergizedScalePositive && isAlertScale && isRestlessScale;
    } else if (
      scales.includes("calmScale") &&
      scales.includes("focussedScale") &&
      scales.includes("restlessScale")
    ) {
      enableContinue = isCalmScalePositive && isFocusedScale && isRestlessScale;
    } else if (
      scales.includes("calmScale") &&
      scales.includes("alertScale") &&
      scales.includes("restlessScale")
    ) {
      enableContinue = isCalmScalePositive && isAlertScale && isRestlessScale;
    } else if (
      scales.includes("focussedScale") &&
      scales.includes("alertScale") &&
      scales.includes("restlessScale")
    ) {
      enableContinue = isFocusedScale && isAlertScale && isRestlessScale;
    } else if (
      scales.includes("happyScale") &&
      scales.includes("energizedScale") &&
      scales.includes("focussedScale")
    ) {
      enableContinue =
        isHappyScalePositive && isEnergizedScalePositive && isFocusedScale;
    } else if (
      scales.includes("happyScale") &&
      scales.includes("energizedScale") &&
      scales.includes("alertScale")
    ) {
      enableContinue =
        isHappyScalePositive && isEnergizedScalePositive && isAlertScale;
    } else if (
      scales.includes("happyScale") &&
      scales.includes("calmScale") &&
      scales.includes("focussedScale")
    ) {
      enableContinue =
        isHappyScalePositive && isCalmScalePositive && isFocusedScale;
    } else if (
      scales.includes("happyScale") &&
      scales.includes("calmScale") &&
      scales.includes("alertScale")
    ) {
      enableContinue =
        isHappyScalePositive && isCalmScalePositive && isAlertScale;
    } else if (
      scales.includes("happyScale") &&
      scales.includes("focussedScale") &&
      scales.includes("alertScale")
    ) {
      enableContinue = isHappyScalePositive && isFocusedScale && isAlertScale;
    } else if (
      scales.includes("calmScale") &&
      scales.includes("energizedScale") &&
      scales.includes("focussedScale")
    ) {
      enableContinue =
        isCalmScalePositive && isEnergizedScalePositive && isFocusedScale;
    } else if (
      scales.includes("calmScale") &&
      scales.includes("energizedScale") &&
      scales.includes("alertScale")
    ) {
      enableContinue =
        isCalmScalePositive && isEnergizedScalePositive && isAlertScale;
    } else if (
      scales.includes("energizedScale") &&
      scales.includes("focussedScale") &&
      scales.includes("alertScale")
    ) {
      enableContinue =
        isEnergizedScalePositive && isFocusedScale && isAlertScale;
    } else if (
      scales.includes("calmScale") &&
      scales.includes("focussedScale") &&
      scales.includes("alertScale")
    ) {
      enableContinue = isCalmScalePositive && isFocusedScale && isAlertScale;
    } else if (
      scales.includes("happyScale") &&
      scales.includes("restlessScale")
    ) {
      enableContinue = isHappyScalePositive && isRestlessScale;
    } else if (
      scales.includes("energizedScale") &&
      scales.includes("restlessScale")
    ) {
      enableContinue = isEnergizedScalePositive && isRestlessScale;
    } else if (
      scales.includes("focussedScale") &&
      scales.includes("restlessScale")
    ) {
      enableContinue = isFocusedScale && isRestlessScale;
    } else if (
      scales.includes("alertScale") &&
      scales.includes("restlessScale")
    ) {
      enableContinue = isAlertScale && isRestlessScale;
    } else if (
      scales.includes("calmScale") &&
      scales.includes("restlessScale")
    ) {
      enableContinue = isCalmScalePositive && isRestlessScale;
    } else if (
      scales.includes("happyScale") &&
      scales.includes("energizedScale")
    ) {
      enableContinue = isHappyScalePositive && isEnergizedScalePositive;
    } else if (scales.includes("happyScale") && scales.includes("calmScale")) {
      enableContinue = isHappyScalePositive && isCalmScalePositive;
    } else if (
      scales.includes("happyScale") &&
      scales.includes("focussedScale")
    ) {
      enableContinue = isHappyScalePositive && isFocusedScale;
    } else if (scales.includes("happyScale") && scales.includes("alertScale")) {
      enableContinue = isHappyScalePositive && isAlertScale;
    } else if (
      scales.includes("calmScale") &&
      scales.includes("energizedScale")
    ) {
      enableContinue = isCalmScalePositive && isEnergizedScalePositive;
    } else if (
      scales.includes("energizedScale") &&
      scales.includes("focussedScale")
    ) {
      enableContinue = isEnergizedScalePositive && isFocusedScale;
    } else if (
      scales.includes("energizedScale") &&
      scales.includes("alertScale")
    ) {
      enableContinue = isEnergizedScalePositive && isAlertScale;
    } else if (
      scales.includes("calmScale") &&
      scales.includes("focussedScale")
    ) {
      enableContinue = isCalmScalePositive && isFocusedScale;
    } else if (scales.includes("calmScale") && scales.includes("alertScale")) {
      enableContinue = isCalmScalePositive && isAlertScale;
    } else if (
      scales.includes("focussedScale") &&
      scales.includes("alertScale")
    ) {
      enableContinue = isFocusedScale && isAlertScale;
    } else if (scales.includes("restlessScale")) {
      enableContinue = isRestlessScale;
    } else if (scales.includes("happyScale")) {
      enableContinue = isHappyScalePositive;
    } else if (scales.includes("energizedScale")) {
      enableContinue = isEnergizedScalePositive;
    } else if (scales.includes("focussedScale")) {
      enableContinue = isFocusedScale;
    } else if (scales.includes("alertScale")) {
      enableContinue = isAlertScale;
    } else if (scales.includes("calmScale")) {
      enableContinue = isCalmScalePositive;
    }

    setEnableContinue(enableContinue);
  }, [
    selectedRange,
    selectedBoredEngagedRange,
    selectedFrustratedCalmRange,
    selectedAlertScaleRange,
    selectedFocusedScaleRange,
    selectedRestlessRange,
    scales,
    isRangeClicked,
    isAlertScaleRangeClicked,
    isBoredEngagedRangeClicked,
    isFocusedScaleRangeClicked,
    isFrustratedCalmRangeClicked,
    isRestlessRangeClicked,
  ]);

  const [result, setResult] = useAsync<SubmitFeedbackResponseDTO>({
    fn: async (data: SubmitFeedbackRequestDTO) => {
      const result = await apiClient.post<
        SubmitFeedbackRequestDTO,
        AxiosResponse<SubmitFeedbackResponseDTO, any>,
        any
      >(submitFeedbackApiPath, data);
      return result.data;
    },
  });

  useEffect(() => {
    (async () => {
      if (isSubmitClick) {
        const dataValue: SubmitFeedbackRequestDTO = {
          like:
            isSmileyClicked.isHappySmileyClicked === true
              ? isSmileyClicked.isHappySmileyClicked
              : isSmileyClicked.isSadSmileyClicked
              ? false
              : isSmileyClicked.isSadSmileyClicked,
          comment: experienceFeedback ? experienceFeedback : "",
          detailedComment: thoughts ? thoughts : "",
          reaction: experience.join(", "),
          userId: playerUserId,
          contentId: playerContentId,
          happyScale: selectedRange ? +selectedRange : 0,
          calmScale: selectedBoredEngagedRange ? +selectedBoredEngagedRange : 0,
          energizedScale: selectedFrustratedCalmRange
            ? +selectedFrustratedCalmRange
            : 0,
          focusedScale: selectedFocusedScaleRange
            ? +selectedFocusedScaleRange
            : 0,
          alertScale: selectedAlertScaleRange ? +selectedAlertScaleRange : 0,
          restlessScale: selectedRestlessRange ? +selectedRestlessRange : 0,
        };
        setResult(dataValue);
      }
    })();
  }, [
    isSubmitClick,
    isSmileyClicked,
    experienceFeedback,
    experience,
    selectedBoredEngagedRange,
    selectedFrustratedCalmRange,
    selectedRange,
    playerUserId,
    playerContentId,
    selectedRestlessRange,
    thoughts,
  ]);

  useEffect(() => {
    const storedNoContent = localStorage.getItem("isLast");
    if (storedNoContent) {
      setIsLast(storedNoContent === "true" ? true : false);
    }
  }, []);

  useEffect(() => {
    if (result.result) {
      const nextIndex = dataIndex + 1;
      localStorage.setItem(
        localStorageLiterals.nextDataIndex,
        nextIndex.toString()
      );
      if (
        isLast &&
        contentData.length > 0 &&
        dataIndex === contentData.length
      ) {
        navigate("/endpage");
      } else {
        navigate("/content");
      }
    }
  }, [result.result, isLast]);

  const handleBackClick = () => {
    setIsContinue(false);
  };
  const handleGameContinueClick = () => {
    setIsSubmitClick(true);
  };

  const handleRangeInitialClick = () => {
    setIsRangeClicked(true);
  };

  const handleBoredEngagedRangeInitialClick = () => {
    setIsBoredEngagedRangeClicked(true);
  };

  const handleFrustratedCalmRangeInitialClick = () => {
    setIsFrustratedCalmRangeClicked(true);
  };

  const handleAlertScaleRangeInitialClick = () => {
    setIsAlertScaleRangeClicked(true);
  };

  const handleFocusedScaleRangeInitialClick = () => {
    setIsFocusedScaleRangeClicked(true);
  };

  const handleRestlessScaleRangeInitialClick = () => {
    setIsRestlessRangeClicked(true);
  };

  const handleAlertScaleClick = (
    selectedAlertScaleRange: number | number[]
  ) => {
    setAlertScaleSelectedRange(selectedAlertScaleRange);
    setIsAlertScaleRangeClicked(true);
  };

  const handleRestlessScaleRange = (
    selectedRestlessRange: number | number[]
  ) => {
    setSelectedRestlessRange(selectedRestlessRange);
    setIsRestlessRangeClicked(true);
  };

  const handleFocusedScaleClick = (
    selectedFocusedScaleRange: number | number[]
  ) => {
    setFocusedScaleSelectedRange(selectedFocusedScaleRange);
    setIsFocusedScaleRangeClicked(true);
  };

  const handleBoredEngagedRangeClick = (
    selectedBoredEngagedRange: number | number[]
  ) => {
    setBoredEngagedSelectedRange(selectedBoredEngagedRange);
    setIsBoredEngagedRangeClicked(true);
  };

  const handleFrustratedCalmRangeClick = (
    selectedFrustratedCalmRange: number | number[]
  ) => {
    setFrustratedCalmSelectedRange(selectedFrustratedCalmRange);
    setIsFrustratedCalmRangeClicked(true);
  };

  const handleRangeClick = (selectedRange: number | number[]) => {
    setSelectedRange(selectedRange);
    setIsRangeClicked(true);
  };

  return (
    <>
      {!result.result && (
        <>
          {isContinue ? (
            <GameFeedbackComponent
              isLoading={result.isLoading}
              scales={scales}
              contentType={
                contentResult.result &&
                contentResult.result.data &&
                contentResult.result.data.contentName
                  ? contentResult.result.data.contentName
                  : ""
              }
              enableContinueButton={enableContinue}
              selectedRange={+selectedRange}
              selectBoredEngagedRange={+selectedBoredEngagedRange}
              selectedFrustratedCalmRange={+selectedFrustratedCalmRange}
              selectedAlertScaleRange={+selectedAlertScaleRange}
              selectedFocusedScaleRange={+selectedFocusedScaleRange}
              selectedRestlessScaleRange={+selectedRestlessRange}
              isAlertScaleRangeClicked={isAlertScaleRangeClicked}
              isBoredEngagedRangeClicked={isBoredEngagedRangeClicked}
              isFocusedScaleRangeClicked={isFocusedScaleRangeClicked}
              isFrustratedCalmRangeClicked={isFrustratedCalmRangeClicked}
              isRangeClicked={isRangeClicked}
              isRestlessRangeClicked={isRestlessRangeClicked}
              handleAlertScaleRangeInitialClick={
                handleAlertScaleRangeInitialClick
              }
              handleBoredEngagedRangeInitialClick={
                handleBoredEngagedRangeInitialClick
              }
              handleFocusedScaleRangeInitialClick={
                handleFocusedScaleRangeInitialClick
              }
              handleFrustratedCalmRangeInitialClick={
                handleFrustratedCalmRangeInitialClick
              }
              handleRangeInitialClick={handleRangeInitialClick}
              handleRestlessScaleRangeInitialClick={
                handleRestlessScaleRangeInitialClick
              }
              handleRestlessScaleRange={handleRestlessScaleRange}
              handleAlertScaleClick={handleAlertScaleClick}
              handleFocusedScaleClick={handleFocusedScaleClick}
              handleBoredEngagedRangeClick={handleBoredEngagedRangeClick}
              handleFrustratedCalmRangeClick={handleFrustratedCalmRangeClick}
              handleRangeClick={handleRangeClick}
              handleBackClick={handleBackClick}
              handleContinueClick={handleGameContinueClick}
            />
          ) : (
            <FeedbackComponent
              enableContinueButton={enableContinueButton}
              experienceOptions={
                isSmileyClicked.isHappySmileyClicked
                  ? happyExperienceOptions
                  : isSmileyClicked.isSadSmileyClicked
                  ? sadExperienceOptions
                  : []
              }
              experience={experience}
              smiley={isSmileyClicked}
              experienceFeedback={experienceFeedback}
              thoughts={thoughts}
              onThoughtChange={setThoughts}
              onExperienceFeedbackChange={setExperienceFeedback}
              onExperienceChange={handleExperienceChange}
              handleHappyClick={handleHappyClick}
              handleSadClick={handleSadClick}
              handleContinueClick={handleContinueClick}
            />
          )}
        </>
      )}
    </>
  );
};
