import { PropsWithChildren, createContext, useState } from "react";

export type Details = {
  age: number;
  name: string;
  gender: string;
  location: string;
  happyScale: number;
  energizedScale: number;
  calmScale: number;
  otherLocation?: string;
};

export type RegisterUserContextProps = {
  registerUserDetails: Details;
  isSadHappyClicked: boolean;
  isTiredEnergizedClicked: boolean;
  isTenseRelaxedClicked: boolean;
  setRegisterUserDetails: (newDetails: Details) => void;
  setIsSadHappyClicked: (isSadHappyClicked: boolean) => void;
  setIsTiredEnergizedClicked: (isTiredEnergizedClicked: boolean) => void;
  setIsTenseRelaxedClicked: (isTenseRelaxedClicked: boolean) => void;
};

export const RegisterUserContext =
  createContext<RegisterUserContextProps | null>(null);

export const RegisterUserContextProvider = ({
  children,
}: PropsWithChildren<{}>) => {
  const [registerUserDetails, setRegisterUserDetails] = useState<Details>({
    age: 0,
    name: "",
    gender: "",
    location: "",
    happyScale: 0,
    energizedScale: 0,
    calmScale: 0,
  });
  const [isSadHappyClicked, setIsSadHappyClicked] = useState<boolean>(false);
  const [isTiredEnergizedClicked, setIsTiredEnergizedClicked] =
    useState<boolean>(false);
  const [isTenseRelaxedClicked, setIsTenseRelaxedClicked] =
    useState<boolean>(false);
  return (
    <RegisterUserContext.Provider
      value={{
        registerUserDetails,
        isSadHappyClicked,
        isTenseRelaxedClicked,
        isTiredEnergizedClicked,
        setRegisterUserDetails,
        setIsSadHappyClicked,
        setIsTenseRelaxedClicked,
        setIsTiredEnergizedClicked,
      }}
    >
      {children}
    </RegisterUserContext.Provider>
  );
};
