import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SleepStoryFeedbackComponent } from "../../components/Feedback/SleepStoryFeedback";
import {
  SubmitFeedbackRequestDTO,
  SubmitFeedbackResponseDTO,
  submitFeedbackApiPath,
} from "../../dto/submitFeedback";
import { localStorageLiterals } from "../../Constants";
import { useAsync } from "../../hooks/use-async";
import { ApiClientContext } from "../../context/ApiClientContext";
import { AxiosResponse } from "axios";
import { REACT_APP_IS_GAME_CODE } from "../../env";

export const SleepStoryFeedbackContainer: React.FC = () => {
  const navigate = useNavigate();
  const apiClient = useContext(ApiClientContext)!;
  const [selectedRange, setSelectedRange] = useState<number | number[]>(0);
  const [selectedAlertSleepyRange, setAlertSleepySelectedRange] = useState<
    number | number[]
  >(0);
  const [enableContinueButton, setEnableContinueButton] = useState(true);

  const [submitFeedback, setSubmitFeedbackData] =
    useState<SubmitFeedbackRequestDTO>();
  const [isSubmitClick, setIsSubmitClick] = useState(false);
  const [userId, setUserId] = useState("");

  const [contentData, setContentData] = useState<any[]>([]);
  const [dataIndex, setDataIndex] = useState<number>(0);

  useEffect(() => {
    const storedContentData = localStorage.getItem(
      localStorageLiterals.contentData
    );
    if (storedContentData) {
      setContentData(JSON.parse(storedContentData));
    }

    const storedDataIndex = localStorage.getItem(
      localStorageLiterals.dataIndex
    );
    if (storedDataIndex) {
      setDataIndex(parseInt(storedDataIndex, 10));
    }
  }, []);

  const [submitFeedbackResult, submitFeedbackCallable] =
    useAsync<SubmitFeedbackResponseDTO>({
      fn: async (data: SubmitFeedbackRequestDTO) => {
        const result = await apiClient.post<
          SubmitFeedbackRequestDTO,
          AxiosResponse<SubmitFeedbackResponseDTO, any>,
          any
        >(submitFeedbackApiPath, data);
        return result.data;
      },
    });

  useEffect(() => {
    const userId = localStorage.getItem(localStorageLiterals.userId);
    if (userId) {
      setUserId(userId);
    }
  }, []);

  useEffect(() => {
    const submitFeedbackData = localStorage.getItem(
      localStorageLiterals.submitFeedback
    );
    if (submitFeedbackData) {
      setSubmitFeedbackData(JSON.parse(submitFeedbackData));
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (isSubmitClick && submitFeedback) {
        const contentId = contentData[dataIndex].id;
        const dataValue = {
          like: submitFeedback.like,
          comment: submitFeedback.comment,
          reaction: submitFeedback.reaction,
          userId: userId,
          contentId: contentId,
          happyScale: +selectedAlertSleepyRange,
          calmScale: +selectedAlertSleepyRange,
          energizedScale: +selectedRange,
        };
        submitFeedbackCallable(dataValue);
        if (REACT_APP_IS_GAME_CODE) {
          const parentWindow = window.parent;
          if (parentWindow) {
            // Redirect to the parent component
            parentWindow.location.href = "/";
          }
          // window.parent.postMessage({ action: "redirect" }, "*");
        }
      }
    })();
  }, [isSubmitClick, submitFeedback]);

  useEffect(() => {
    if (submitFeedbackResult.result) {
      if (dataIndex !== contentData.length - 1) {
        const updatedIndex = dataIndex + 1;
        setDataIndex(updatedIndex);
        localStorage.setItem(
          localStorageLiterals.dataIndex,
          updatedIndex.toString()
        );
        localStorage.removeItem(localStorageLiterals.submitFeedback);
        navigate("/content");
      } else {
        if (dataIndex === contentData.length - 1) {
          const updatedIndex = dataIndex + 1;
          setDataIndex(updatedIndex);
          localStorage.setItem(
            localStorageLiterals.dataIndex,
            updatedIndex.toString()
          );
          localStorage.setItem(
            localStorageLiterals.contentData,
            JSON.stringify(["NO DATA"])
          );
          localStorage.removeItem(localStorageLiterals.submitFeedback);
          navigate("/content");
        }
      }
    }
  }, [submitFeedbackResult.result]);

  const handleBackClick = () => {
    navigate("/feedback");
  };
  const handleContinueClick = () => {
    setIsSubmitClick(true);
  };

  return (
    <SleepStoryFeedbackComponent
      isLoading={submitFeedbackResult.isLoading}
      enableContinueButton={enableContinueButton}
      selectedRange={+selectedRange}
      selectAlertSleepyRange={+selectedAlertSleepyRange}
      handleAlertSleepyRangeClick={setAlertSleepySelectedRange}
      handleRangeClick={setSelectedRange}
      handleBackClick={handleBackClick}
      handleContinueClick={handleContinueClick}
    />
  );
};
