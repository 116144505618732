import { useEffect, useState } from "react";
import { MeditationFeedbackComponent } from "../../components/Feedback/MeditationFeedback";
import { useNavigate } from "react-router-dom";

export const MeditationFeedbackContainer: React.FC = () => {
  const navigate = useNavigate();
  const [selectedRange, setSelectedRange] = useState<string>("");
  const [enableContinueButton, setEnableContinueButton] = useState(false);

  const handleBackClick = () => {
    navigate("/feedback");
  };
  const handleContinueClick = () => {
    navigate("/story-feedback");
  };

  useEffect(() => {
    if (selectedRange) {
      setEnableContinueButton(true);
    } else {
      setEnableContinueButton(false);
    }
  }, [selectedRange]);

  return (
    <MeditationFeedbackComponent
      enableContinueButton={enableContinueButton}
      selectedRange={selectedRange}
      handleRangeClick={setSelectedRange}
      handleBackClick={handleBackClick}
      handleContinueClick={handleContinueClick}
    />
  );
};
