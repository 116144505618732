import groupImage from "../asset/GroupLogo.svg";
import charcoDeadline from "../asset/CharcoPicture.svg";
import selectImage from "../asset/Select.svg";
import { Box, Button, Theme, Typography } from "@mui/material";
import { isMobile, deviceType } from "react-device-detect";
import { SxProps } from "@mui/system";
import { messages } from "../Constants";
import { LoaderComponent } from "./Loader";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { useState } from "react";

const trackStyles: React.CSSProperties = {
  backgroundColor: "#BF50B2",
  height: "8px",
};

const disableTrackStyles: React.CSSProperties = {
  backgroundColor: "#909090",
  height: "8px",
};

const handleStyles: React.CSSProperties = {
  borderColor: "#BF50B2",
  backgroundColor: "white",
  height: "20px",
  width: "20px",
  boxShadow: "none",
};

const disableHandleStyles: React.CSSProperties = {
  borderColor: "#909090",
  backgroundColor: "white",
  height: "20px",
  width: "20px",
  boxShadow: "none",
};

const railStyles: React.CSSProperties = {
  height: "8px",
};

const activeHandleStyle: React.CSSProperties = {
  boxShadow: "none",
};

const markStyle: React.CSSProperties = {
  fontSize: "9px",
  color: "black",
  position: "absolute",
  top: 0,
  lineHeight: "1px",
};

const container: SxProps<Theme> = {
  width: "100vw",
  height: isMobile && deviceType !== "tablet" ? "auto" : "100vh",
  minHeight: isMobile && deviceType !== "tablet" ? "100vh" : "auto",
  background: "#FFF",
  display: "flex",
  flex: 1,
  flexDirection: isMobile && deviceType !== "tablet" ? "column-reverse" : "row",
  boxSizing: "border-box",
  overflowY: "auto",
  justifyContent: "space-between",
  color: "#4D2D66",
};

const rightsideContainer: SxProps<Theme> = {
  display: "flex",
  width: isMobile ? "100%" : "25%",
  minHeight: isMobile ? "15vh" : "auto",
  backgroundColor: "#F0E4EE",
  alignItems: "center",
  justifyContent: "center",
};

const leftsideContainer: SxProps<Theme> = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  alignContent: "center",
  alignItems: "center",
  justifyContent: "center",
};

const leftInnerContainer: SxProps<Theme> = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  alignContent: "center",
  alignItems: "center",
  justifyContent: "center",
  width: isMobile ? "80%" : "40%",
};

const charcoImage = {
  display: "flex",
  flex: 1,
  width: isMobile ? "15vh" : "318px",
  height: isMobile ? "15vh" : "238px",
  padding: "10px",
  paddingTop: "40px",
  alignSelf: "center",
};

const logoImage = {
  width: "54px",
  height: "54px",
  paddingBottom: "25px",
};

const title: SxProps<Theme> = {
  fontFamily: "DM Sans Light",
  fontSize: isMobile ? (deviceType === "tablet" ? "28px" : "24px") : "32px",
  color: "#4D2D66",
  lineHeight: isMobile
    ? deviceType === "tablet"
      ? "35px"
      : "30px"
    : "41.66px",
  textAlign: "center",
  marginBottom: "15px",
};

const innerContainer: SxProps<Theme> = {
  display: "flex",
  alignContent: "space-between",
  width: "100%",
  paddingBottom: "5px",
  textAlign: "left",
  marginTop: "20px",
  flexDirection: deviceType === "mobile" ? "column-reverse" : "row",
};

const continueButton: SxProps<Theme> = {
  fontFamily: "DM Sans",
  textTransform: "none",
  height: "50px",
  width: "100%",
  alignSelf: "center",
  backgroundColor: "#BF50B2",
  textAlign: "center",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "14px",
  letterSpacing: "1.5px",
  border: "none",
  borderRadius: "56px",
  outline: "none",
  marginLeft: deviceType === "mobile" ? 0 : "20px",
  color: "white",
  ":hover": {
    backgroundColor: "#BF50B2",
    color: "white",
  },
};

const disableContinueButton: SxProps<Theme> = {
  fontFamily: "DM Sans",
  textTransform: "none",
  height: "50px",
  width: "100%",
  alignSelf: "center",
  backgroundColor: "#e5c4e1",
  textAlign: "center",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "14px",
  letterSpacing: "1.5px",
  border: "none",
  borderRadius: "56px",
  outline: "none",
  marginLeft: deviceType === "mobile" ? 0 : "20px",
  color: "white",
  ":hover": {
    backgroundColor: "#e5c4e1",
    color: "white",
  },
};

const backButton: SxProps<Theme> = {
  fontFamily: "DM Sans",
  textTransform: "none",
  height: "50px",
  width: "100%",
  alignSelf: "center",
  backgroundColor: "#FFFFFF",
  textAlign: "center",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "14px",
  letterSpacing: "1.5px",
  border: "1px solid #000", // Specify both border style and color
  borderRadius: "56px",
  outline: "none",
  color: "#000",
  marginTop: deviceType === "mobile" ? "15px" : 0,
  marginRight: deviceType === "mobile" ? 0 : "20px",
  marginLeft: deviceType === "mobile" ? 0 : "20px",
  ":hover": {
    backgroundColor: "#FFFFFF",
    color: "#000",
  },
};

const dataList: SxProps<Theme> = {
  marginBottom: "5px",
  display: "flex",
  width: "85%",
  flexDirection: "column",
  alignItems: "center",
  marginTop: isMobile ? (deviceType !== "tablet" ? "30px" : "120px") : "30px",
};

const customSlider = {
  display: "flex",
  width: "100%",
};

const trackStyle: React.CSSProperties = {
  backgroundColor: "#BF50B2",
  height: "3px",
};

const handleStyle: React.CSSProperties = {
  border: "4px solid #BF50B2",
  backgroundColor: "#FFFFFF",
  height: "16px",
  width: "16px",
  boxShadow: "none",
  position: "absolute",
  top: "2px",
  zIndex: 999,
};

const railStyle: React.CSSProperties = {
  height: "3px",
  backgroundColor: "#CFCFCF",
};

const activeHandle: React.CSSProperties = {
  position: "absolute",
  top: "-7px",
  border: "0px solid #CFCFCF",
  backgroundImage: `url(${selectImage})`,
  height: "16px",
  width: "16px",
  boxShadow: "none",
};

const dotStyle: React.CSSProperties = {
  position: "absolute",
  top: "-7px",
  border: "4px solid #CFCFCF",
  backgroundColor: "white",
  height: "16px",
  width: "16px",
  boxShadow: "none",
};

const mark: React.CSSProperties = {
  fontSize: "14px",
  color: "black",
  position: "absolute",
  top: "0px", // Adjust this value based on your preference for vertical positioning
  transform: "translateX(-50%)", // Center the mark horizontally
  whiteSpace: "nowrap", // Prevent line breaks
};

const optionsContainer: SxProps<Theme> = {
  display: "flex",
  flexDirection: "row",
  width: "100%",
  justifyContent: "space-between",
  marginBottom: !isMobile ? "15px" : "10px",
  marginTop: !isMobile ? "15px" : "10px",
};

const optionStyle: React.CSSProperties = {
  textAlign: "center",
  cursor: "pointer",
  borderBottom: "2px solid transparent",
  fontSize: "14px",
};

type FeelingsComponentProps = {
  isLoading: boolean;
  selectedRange: number;
  selectedSadHappyRange: number;
  selectedTenseRelaxedRange: number;
  selectedTiredEnergizedRange: number;
  isSadHappyClicked: boolean;
  isTiredEnergizedClicked: boolean;
  isTenseRelaxedClicked: boolean;
  handleAfterSadHappy: () => void;
  handleAfterTiredEnergized: () => void;
  handleAfterTenseRelaxed: () => void;
  handleTiredEnergizedClick(
    selectedTiredEnergizedRange: number | number[]
  ): void;
  handleTenseRelaxedClick(selectedTenseRelaxedRange: number | number[]): void;
  handleSadHappyRangeClick(selectedSadHappyRange: number | number[]): void;
  handleRangeClick(selectedRange: number | number[]): void;
  handleBackClick(): void;
  handleContinueClick(): void;
};

export const FeelingsComponent: React.FC<FeelingsComponentProps> = ({
  isLoading,
  selectedRange,
  selectedSadHappyRange,
  selectedTenseRelaxedRange,
  selectedTiredEnergizedRange,
  isSadHappyClicked,
  isTenseRelaxedClicked,
  isTiredEnergizedClicked,
  handleAfterSadHappy,
  handleAfterTenseRelaxed,
  handleAfterTiredEnergized,
  handleTenseRelaxedClick,
  handleTiredEnergizedClick,
  handleSadHappyRangeClick,
  handleRangeClick,
  handleBackClick,
  handleContinueClick,
}) => {
  return (
    <>
      {isLoading ? (
        <LoaderComponent />
      ) : (
        <Box sx={container}>
          <Box sx={leftsideContainer}>
            <Box sx={leftInnerContainer}>
              <Box sx={dataList}>
                <Slider
                  min={1}
                  max={5}
                  trackStyle={trackStyle}
                  handleStyle={handleStyle}
                  railStyle={railStyle}
                  activeDotStyle={activeHandle}
                  dotStyle={dotStyle}
                  marks={{
                    "1": <span style={mark}>Name</span>,
                    "2": <span style={mark}>Age</span>,
                    "3": <span style={mark}>Gender</span>,
                    "4": <span style={mark}>Location</span>,
                    "5": <span style={mark}>Feelings</span>,
                  }}
                  value={selectedRange}
                  defaultValue={5}
                  style={customSlider}
                  onChange={handleRangeClick}
                />
              </Box>
              <Box
                sx={{
                  ...leftInnerContainer,
                  width: deviceType === "mobile" ? "100%" : "80%",
                  flex: 1,
                  marginBottom: isMobile
                    ? deviceType !== "tablet"
                      ? "100px"
                      : "120px"
                    : "80px",
                  marginTop: isMobile
                    ? deviceType !== "tablet"
                      ? "50px"
                      : 0
                    : 0,
                }}
              >
                <img style={logoImage} src={groupImage} alt="iphone" />
                <Typography sx={title}>{messages.howAreYouFeeling}</Typography>
                <Slider
                  min={-3}
                  max={3}
                  value={selectedSadHappyRange}
                  trackStyle={
                    !isSadHappyClicked ? disableTrackStyles : trackStyles
                  }
                  handleStyle={
                    !isSadHappyClicked ? disableHandleStyles : handleStyles
                  }
                  railStyle={railStyles}
                  defaultValue={0}
                  activeDotStyle={activeHandleStyle}
                  marks={{
                    "-3": <span style={markStyle}> |</span>,
                    "-2": <span style={markStyle}>|</span>,
                    "-1": <span style={markStyle}>|</span>,
                    "0": <span style={markStyle}>|</span>,
                    "1": <span style={markStyle}>|</span>,
                    "2": <span style={markStyle}>|</span>,
                    "3": <span style={markStyle}>| </span>,
                  }}
                  dotStyle={{ display: "none" }}
                  onChange={(value) => {
                    handleSadHappyRangeClick(value);
                  }}
                  onChangeComplete={handleAfterSadHappy}
                />
                <Box sx={optionsContainer}>
                  <div
                    style={optionStyle}
                  >
                    Sad
                  </div>
                  <div
                    style={optionStyle}
                  >
                    Happy
                  </div>
                </Box>
                <Slider
                  min={-3}
                  max={3}
                  value={selectedTenseRelaxedRange}
                  trackStyle={
                    !isTenseRelaxedClicked ? disableTrackStyles : trackStyles
                  }
                  handleStyle={
                    !isTenseRelaxedClicked ? disableHandleStyles : handleStyles
                  }
                  railStyle={railStyles}
                  defaultValue={0}
                  activeDotStyle={activeHandleStyle}
                  marks={{
                    "-3": <span style={markStyle}> |</span>,
                    "-2": <span style={markStyle}>|</span>,
                    "-1": <span style={markStyle}>|</span>,
                    "0": <span style={markStyle}>|</span>,
                    "1": <span style={markStyle}>|</span>,
                    "2": <span style={markStyle}>|</span>,
                    "3": <span style={markStyle}>| </span>,
                  }}
                  dotStyle={{ display: "none" }}
                  onChange={handleTenseRelaxedClick}
                  onChangeComplete={handleAfterTenseRelaxed}
                />
                <Box sx={optionsContainer}>
                  <div
                    style={optionStyle}
                  >
                    Anxious/Tense
                  </div>
                  <div
                    style={optionStyle}
                  >
                    Calm/Relaxed
                  </div>
                </Box>
                <Slider
                  min={-3}
                  max={3}
                  value={selectedTiredEnergizedRange}
                  trackStyle={
                    !isTiredEnergizedClicked ? disableTrackStyles : trackStyles
                  }
                  handleStyle={
                    !isTiredEnergizedClicked
                      ? disableHandleStyles
                      : handleStyles
                  }
                  railStyle={railStyles}
                  defaultValue={0}
                  activeDotStyle={activeHandleStyle}
                  marks={{
                    "-3": <span style={markStyle}> |</span>,
                    "-2": <span style={markStyle}>|</span>,
                    "-1": <span style={markStyle}>|</span>,
                    "0": <span style={markStyle}>|</span>,
                    "1": <span style={markStyle}>|</span>,
                    "2": <span style={markStyle}>|</span>,
                    "3": <span style={markStyle}>| </span>,
                  }}
                  dotStyle={{ display: "none" }}
                  onChange={handleTiredEnergizedClick}
                  onChangeComplete={handleAfterTiredEnergized}
                />
                <Box sx={optionsContainer}>
                  <div
                    style={optionStyle}
                  >
                    Tired
                  </div>
                  <div
                    style={optionStyle}
                  >
                    Energized
                  </div>
                </Box>
                <Box sx={innerContainer}>
                  <Button sx={backButton} onClick={handleBackClick}>
                    {messages.back}
                  </Button>
                  <Button
                    sx={
                      !isSadHappyClicked ||
                      !isTenseRelaxedClicked ||
                      !isTiredEnergizedClicked
                        ? disableContinueButton
                        : continueButton
                    }
                    onClick={handleContinueClick}
                    disabled={
                      !isSadHappyClicked ||
                      !isTenseRelaxedClicked ||
                      !isTiredEnergizedClicked
                    }
                  >
                    {messages.startMessage}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
          {!isMobile && (
            <Box sx={rightsideContainer}>
              <img style={charcoImage} src={charcoDeadline} alt="iphone" />
            </Box>
          )}
        </Box>
      )}
    </>
  );
};
