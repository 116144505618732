import React, { useEffect, useRef, useState } from "react";
import p5 from "p5";
import "./Sketch.css"; // Import the CSS file
import Button from "@mui/material/Button";
import Eraser from "../../asset/Eraser.svg";
import Download from "../../asset/Download.svg";
import AI from "../../asset/AI.svg";
import Symmetry from "../../asset/Symmetry.svg";
import Pencil from "../../asset/Pencil.svg";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Typography } from "@mui/material";
import { isMobile, deviceType } from "react-device-detect";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import axios from "axios";
import IconButton from "@mui/material/IconButton";
import CancelSharpIcon from "@mui/icons-material/CancelSharp";
import Feedback from "./Feedback";
import LoadingAnimation from "./Loader";
import { REACT_APP_IS_GAME_CODE } from "../../env";
import { localStorageLiterals } from "../../Constants";
import { useNavigate } from "react-router-dom";

const Sketch = () => {
  const navigate = useNavigate();
  const canvasRef = useRef(null);
  const canvasDataRef = useRef(null);
  const p5InstanceRef = useRef(null);
  const historyRef = useRef([]);
  const [selectedTool, setSelectedTool] = useState("pencil");
  const [aiImageType, setAiImageType] = useState("Flower");
  const [generatedImage, setGeneratedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isClosedButtonClicked, setIsClosedButtonClicked] = useState(false);
  const [displayCanvas, setDisplayCanvas] = useState(true);

  const loadingText = [
    " 1/4: Adding colors...",
    " 2/4: Blending strokes...",
    " 3/4: Enhancing details...",
    " 4/4: Finalizing your art...",
  ];

  const animationDuration = 100;

  useEffect(() => {
    let canvas;

    const sketch = (p) => {
      p.setup = () => {
        if (canvasRef.current) {
          canvas = p.createCanvas(400, 400);
          console.log("checkpoint 1");
          console.log("canvasRef:", canvasRef);
          canvas.parent(canvasRef.current); // Set the parent to the canvas ref
          console.log("checkpoint 2");
          canvas.class("my-canvas");
          p.background("#FFFFFF");
          p.stroke(0);
          p5InstanceRef.current = p;
          canvasDataRef.current = canvas;
        }
      };

      p.draw = () => {
        if (p.mouseIsPressed) {
          const x1 = p.mouseX;
          const y1 = p.mouseY;
          const x2 = p.pmouseX;
          const y2 = p.pmouseY;

          // Draw the stroke
          p.line(x1, y1, x2, y2);

          // If symmetry is selected, draw a mirrored stroke
          if (selectedTool === "symmetry") {
            const mirrorX = p.width - x1;
            p.line(mirrorX, y1, p.width - x2, y2);
          }

          // Save the stroke for undo
          const line = { x1, y1, x2, y2 };
          historyRef.current.push(line);
        }
      };

      //   p.mousePressed = () => {
      //     // Clear the canvas when switching tools
      //     if (selectedTool !== 'symmetry') {
      //       p5Instance.clear();
      //       p5Instance.background(220);
      //       historyRef.current = [];
      //     }
      //   };
    };

    const newP5 = new p5(sketch);

    return () => {
      newP5.remove();
    };
  }, [selectedTool, displayCanvas]);

  const saveImage = () => {
    // Create an anchor element
    const link = document.createElement("a");
    link.href = generatedImage;
    link.download = "image.png"; // Set the desired filename for the downloaded image
    link.target = "_blank"; // Open the link in a new tab (optional)

    // Trigger the download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleClearCanvas = () => {
    if (p5InstanceRef.current && historyRef.current.length > 0) {
      p5InstanceRef.current.clear();
      p5InstanceRef.current.background("#FFFFFF");
      historyRef.current.pop();

      //   historyRef.current.forEach((line) => {
      //     p5Instance.line(line.x1, line.y1, line.x2, line.y2);
      //   });
    }
    setGeneratedImage(null);
    setDisplayCanvas(true);
  };

  const handleToolChange = (tool) => {
    setSelectedTool(tool);
  };

  const handleDropdownChange = (event) => {
    setAiImageType(event.target.value);
  };

  const generateAIImage = () => {
    setLoading(true);
    const base64Image = canvasDataRef.current.canvas
      .toDataURL("image/png")
      .split(",")[1];
    const selectedPrompt = aiImageType;

    let promptText;

    switch (selectedPrompt) {
      case "Flower":
        promptText =
          "a beautiful macro HD photograph of a beautiful pink flower";
        break;
      case "Octopus":
        promptText =
          "a beautiful line drawing illustration of a pile of octopus tentacles wrapped around, anime style, by miyazaki";
        break;
      case "Landscape":
        promptText =
          "a beautiful watercolor painting of a landscape with a river and a mountain by jmw turner";
        break;
      case "Mandala":
        promptText =
          "a beautiful macro HD photograph of a brightly painted wooden";
        break;
      default:
        promptText = "";
    }

    const payload = {
      prompt: promptText,
      negative_prompt: "",
      seed: -1,
      steps: 5,
      width: 650,
      height: 650,
      cfg_scale: 7,
      sampler_name: "DPM++ 2M Karras",
      n_iter: 1,
      batch_size: 1,
      alwayson_scripts: {
        ControlNet: {
          args: [
            {
              batch_images: "",
              control_mode: "Balanced",
              enabled: true,
              guidance_end: 1,
              guidance_start: 0,
              image: {
                image: base64Image,
                mask: null,
              },
              input_mode: "simple",
              is_ui: true,
              loopback: false,
              low_vram: false,
              model: "control_sd15_scribble [fef5e48e]",
              module: "canny",
              output_dir: "",
              pixel_perfect: false,
              processor_res: 650,
              resize_mode: "Crop and Resize",
              threshold_a: 100,
              threshold_b: 200,
              weight: 1,
            },
          ],
        },
      },
    };

    const url = "https://web-app.q-u-i-l-t.com/sdapi/v1/txt2img";
    const headers = {
      "Content-Type": "application/json",
    };

    axios
      .post(url, payload, { headers })
      .then((response) => {
        setLoading(false);
        if (!response.status === 200) {
          throw new Error("Network response was not ok");
        }
        const base64Str = response.data.images[0];
        const imageDataURL = base64Str.includes("data:image/png;base64,")
          ? base64Str
          : "data:image/png;base64," + base64Str;

        setGeneratedImage(imageDataURL);
        setDisplayCanvas(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error from AI Image API:", error);
      });
  };

  const onClose = () => {
    setIsClosedButtonClicked(true);
  };

  let userId = localStorage.getItem("userId");
  let storedContentData = localStorage.getItem(
    localStorageLiterals?.contentData
  );

  if (storedContentData) {
    storedContentData = JSON.parse(storedContentData);
  }

  let dataIndexString = localStorage.getItem(localStorageLiterals?.dataIndex);

  let dataIndex;

  if (dataIndexString) {
    dataIndex = parseInt(dataIndexString);
  }

  let contentId= storedContentData[dataIndex].id;

  useEffect(() => {
    if (isClosedButtonClicked) {
      if (REACT_APP_IS_GAME_CODE) {
        const topWindow = window.top;
        if (topWindow) {
          topWindow.location.href = `/feedback?userId=${userId}&contentId=${contentId}`;
        }
      } else {
        navigate(`/feedback?userId=${userId}&contentId=${contentId}`);
      }
    }
  }, [isClosedButtonClicked, REACT_APP_IS_GAME_CODE]);

  return (
    <div className="parent-container">
      <IconButton className="close-button" onClick={onClose}>
        <CancelSharpIcon sx={{ color: "black", fontSize: 30 }} />
      </IconButton>
      <div className="sketch-container">
        {loading && (
          <div className="overlay">
            <LoadingAnimation
              loadingText={loadingText}
              animationDuration={animationDuration}
            />
          </div>
        )}
        {/* Layout for mobile and tablets */}
        {(isMobile || deviceType === "tablet") &&
        canvasRef &&
        !generatedImage &&
        displayCanvas ? (
          <div className="canvas-container" ref={canvasRef}></div>
        ) : null}

        {isMobile && generatedImage ? (
          <img src={generatedImage} className="my-canvas-image" alt="Sample" />
        ) : null}

        <div className="tools-container">
          <div className="upper-tools-container">
            <div className="pencil-container">
              <Button
                startIcon={<img src={Pencil} alt="Pencil Icon" />}
                onClick={() => handleToolChange("pencil")}
                className={selectedTool === "pencil" ? "selected" : "pencil"}
              >
                <Typography variant="body1" className="typography">
                  Pencil
                </Typography>
              </Button>
              <Button
                startIcon={<img src={Symmetry} alt="Symmetry Icon" />}
                onClick={() => handleToolChange("symmetry")}
                className={
                  selectedTool === "symmetry" ? "selected" : "symmetry"
                }
              >
                <Typography variant="body1" className="typography">
                  Symmetry
                </Typography>
              </Button>
            </div>

            <div className="line-between"></div>

            <div className="Ai-container">
              <FormControl fullWidth>
                <Select
                  labelId="dropdown"
                  id="dropdown"
                  value={aiImageType}
                  className="dropdown"
                  onChange={handleDropdownChange}
                  // input={<Typography variant="body1" className="select-typography" />}
                >
                  <MenuItem value={"Flower"}>
                    <Typography variant="body1" className="typography">
                      Flower
                    </Typography>
                  </MenuItem>
                  <MenuItem value={"Mandala"}>
                    <Typography variant="body1" className="typography">
                      Mandala
                    </Typography>
                  </MenuItem>
                  <MenuItem value={"Landscape"}>
                    <Typography variant="body1" className="typography">
                      Landscape
                    </Typography>
                  </MenuItem>
                  <MenuItem value={"Octopus"}>
                    <Typography variant="body1" className="typography">
                      Octopus
                    </Typography>
                  </MenuItem>
                </Select>
              </FormControl>
              <Button
                startIcon={<img src={AI} alt="AI Icon" />}
                className="generate-button"
              >
                <Typography
                  variant="body1"
                  className="generate-image-button-typography"
                  onClick={generateAIImage}
                >
                  Generate AI Image
                </Typography>
              </Button>
            </div>
          </div>
          <div className="lower-tools-container">
            <Button
              variant="text"
              startIcon={<img src={Eraser} alt="Eraser Icon" />}
              onClick={handleClearCanvas}
              className="clear-canvas-button"
            >
              <Typography
                variant="body1"
                className="lower-container-typography"
              >
                Clear Canvas
              </Typography>
            </Button>
            <Button
              variant="text"
              startIcon={<img src={Download} alt="Download Icon" />}
              onClick={saveImage}
              className="clear-canvas-button"
            >
              <Typography
                variant="body1"
                className="lower-container-typography"
              >
                Save Image
              </Typography>
            </Button>
          </div>
        </div>

        {!isMobile && generatedImage ? (
          <img src={generatedImage} className="my-canvas" alt="Sample" />
        ) : null}

        {canvasRef && !isMobile && !generatedImage && displayCanvas ? (
          <div className="canvas-container" ref={canvasRef}></div>
        ) : null}
      </div>
    </div>
  )
};

export default Sketch;
