import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FeelingsComponent } from "../components/Feelings";
import {
  Details,
  RegisterUserContext,
  RegisterUserContextProps,
} from "../common/RegisterUserContext";
import { ApiClientContext } from "../context/ApiClientContext";
import { useAsync } from "../hooks/use-async";
import {
  RegisterUserRequestDTO,
  RegisterUserResponseDTO,
  registerUserApiPath,
} from "../dto/registerUser";
import { AxiosResponse } from "axios";
import { localStorageLiterals } from "../Constants";

export const FeelingsContainer: React.FC = () => {
  const apiClient = useContext(ApiClientContext)!;
  const [isStartClick, setStartClick] = useState(false);
  const navigate = useNavigate();
  const [selectedRange, setSelectedRange] = useState<number | number[]>(5);

  const [result, setResult] = useAsync<RegisterUserResponseDTO>({
    fn: async (data: RegisterUserRequestDTO) => {
      if (data) {
        const result = await apiClient.post<
          RegisterUserRequestDTO,
          AxiosResponse<RegisterUserResponseDTO, any>,
          any
        >(registerUserApiPath, JSON.stringify(data), {
          headers: {
            "Content-Type": "application/json",
          },
        });
        return result.data as any;
      }
    },
  });

  const {
    registerUserDetails,
    isSadHappyClicked,
    isTenseRelaxedClicked,
    isTiredEnergizedClicked,
    setIsSadHappyClicked,
    setIsTenseRelaxedClicked,
    setIsTiredEnergizedClicked,
    setRegisterUserDetails,
  } = useContext(RegisterUserContext) as RegisterUserContextProps;

  useEffect(() => {
    if (isStartClick) {
      const {
        age,
        gender,
        name,
        location,
        otherLocation,
        happyScale,
        calmScale,
        energizedScale,
      } = registerUserDetails;
      if (age !== 0 && gender !== "") {
        const dataValue: RegisterUserRequestDTO = {
          age: age,
          name: name,
          gender: gender,
          location: location === "OTHER" ? otherLocation! : location,
          happyScale: happyScale,
          calmScale: calmScale,
          energizedScale: energizedScale,
        };
        setResult(dataValue);
      } else {
        navigate("/name");
      }
    }
  }, [isStartClick]);

  const handleBackClick = () => {
    navigate("/location");
  };
  
  const handleContinueClick = () => {
    const registerUserData: Details = {
      ...registerUserDetails,
      happyScale: registerUserDetails.happyScale,
      calmScale: registerUserDetails.calmScale,
      energizedScale: registerUserDetails.energizedScale,
    };
    localStorage.setItem(
      localStorageLiterals.registerUser,
      JSON.stringify(registerUserData)
    );
    setStartClick(true);
  };

  const onHappyScaleClick = (value: number | number[]) => {
    if (typeof value === "number") {
      setIsSadHappyClicked(true);
      setRegisterUserDetails({
        ...registerUserDetails,
        happyScale: value,
      });
    }
  };

  const onCalmScaleClick = (value: number | number[]) => {
    if (typeof value === "number") {
      setIsTenseRelaxedClicked(true);
      setRegisterUserDetails({
        ...registerUserDetails,
        calmScale: value,
      });
    }
  };

  const onEnergizedScaleClick = (value: number | number[]) => {
    if (typeof value === "number") {
      setIsTiredEnergizedClicked(true);
      setRegisterUserDetails({
        ...registerUserDetails,
        energizedScale: value,
      });
    }
  };

  const handleAfterSadHappy = () => {
    setIsSadHappyClicked(true);
  };

  const handleAfterTenseRelaxed = () => {
    setIsTenseRelaxedClicked(true);
  };

  const handleAfterTiredEnergized = () => {
    setIsTiredEnergizedClicked(true);
  };

  useEffect(() => {
    if (result.result) {
      const userId = result.result.data.userId;
      localStorage.setItem(localStorageLiterals.userId, userId);
      navigate("/content");
    }
  }, [result.result]);

  return (
    <FeelingsComponent
      isLoading={result.isLoading}
      selectedRange={+selectedRange}
      selectedSadHappyRange={registerUserDetails.happyScale!}
      selectedTenseRelaxedRange={registerUserDetails.calmScale!}
      selectedTiredEnergizedRange={registerUserDetails.energizedScale!}
      isSadHappyClicked={isSadHappyClicked}
      isTenseRelaxedClicked={isTenseRelaxedClicked}
      isTiredEnergizedClicked={isTiredEnergizedClicked}
      handleAfterSadHappy={handleAfterSadHappy}
      handleAfterTenseRelaxed={handleAfterTenseRelaxed}
      handleAfterTiredEnergized={handleAfterTiredEnergized}
      handleTenseRelaxedClick={onCalmScaleClick}
      handleTiredEnergizedClick={onEnergizedScaleClick}
      handleSadHappyRangeClick={onHappyScaleClick}
      handleRangeClick={setSelectedRange}
      handleBackClick={handleBackClick}
      handleContinueClick={handleContinueClick}
    />
  );
};
