import React, { useState, useEffect } from "react";
import "./Sketch.css"; // Import the CSS file
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

const LoadingAnimation = ({ loadingText, animationDuration }: any) => {
  const [textIndex, setTextIndex] = useState(0);
  const [charIndex, setCharIndex] = useState(0);

  useEffect(() => {
    const typeText = () => {
      if (textIndex >= loadingText.length) {
        return;
      }

      if (charIndex <= loadingText[textIndex].length) {
        setTimeout(() => {
          setCharIndex(charIndex + 1);
        }, animationDuration);
      } else {
        setTimeout(() => {
          setTextIndex(textIndex + 1);
          console.log(loadingText[textIndex - 1]);
          setCharIndex(0);
        }, animationDuration * 20);
      }
    };

    // console.log(loadingText[textIndex]?.slice(0, charIndex));
    const interval = setInterval(typeText, animationDuration);

    return () => clearInterval(interval);
  }, [textIndex, charIndex, loadingText, animationDuration]);

  return (
    <Box className="loadingBox">
      <Typography className="typography">Please wait...</Typography>
      <Typography className="small-typography">
        <div>{loadingText[textIndex]?.slice(0, charIndex)}</div>
      </Typography>
      <CircularProgress color="inherit" />
    </Box>
  );
};

export default LoadingAnimation;
