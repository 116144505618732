import React, { useContext, useEffect, useState } from "react";
import { ContentComponent } from "../components/Content";
import { LoaderComponent } from "../components/Loader";
import { ContentResponseDTO, contentApiPath } from "../dto/content";
import { useAsyncEffect } from "../hooks/use-async";
import { localStorageLiterals } from "../Constants";
import { useNavigate } from "react-router-dom";
import { ApiClientContext } from "../context/ApiClientContext";

export const ContentContainer: React.FC = () => {
  const navigate = useNavigate();
  const apiClient = useContext(ApiClientContext)!;
  const [userId, setUserId] = useState<string | null>(null);
  const [contentId, setContentId] = useState<string | null>(null);
  const [dataIndex, setDataIndex] = useState<number>(0);
  const [nextIndex, setNextIndex] = useState<number>(0);
  const [contentData, setContentData] = useState<any[]>([]);

  const [contentResult, setContentResult] = useAsyncEffect<ContentResponseDTO>({
    fn: async () => {
      if (dataIndex === 0 && contentData.length === 0 && userId) {
        try {
          const result = await apiClient.get(
            `${contentApiPath}?userId=${userId}`
          );
          return result.data as any;
        } catch (error) {
          // Handle CORS error by using CORS proxy
          const proxyUrl = `https://cors-anywhere.herokuapp.com/${contentApiPath}?userId=${userId}`;
          const result = await apiClient.get(proxyUrl);
          return result.data as any;
        }
      }
    },
    dependencies: [],
  });

  useEffect(() => {
    (async () => {
      if (
        (dataIndex === 0 ||
          (contentData.length > 0 && dataIndex === contentData.length)) &&
        contentData.length === 0 &&
        userId
      ) {
        await setContentResult();
      }
    })();
  }, [dataIndex, contentData, userId]);

  useEffect(() => {
    if (nextIndex) {
      setDataIndex(nextIndex);
    }
  }, [nextIndex]);

  useEffect(() => {
    const storedUserId = localStorage.getItem(localStorageLiterals.userId);
    if (storedUserId) {
      setUserId(storedUserId);
    }

    const storedDataIndex = localStorage.getItem(
      localStorageLiterals.dataIndex
    );

    if (storedDataIndex) {
      setDataIndex(parseInt(storedDataIndex, 10));
    }

    const storedNextDataIndex = localStorage.getItem(
      localStorageLiterals.nextDataIndex
    );

    if (storedNextDataIndex) {
      setNextIndex(parseInt(storedNextDataIndex, 10));
    }

    const storedContentData = localStorage.getItem(
      localStorageLiterals.contentData
    );

    if (storedContentData) {
      setContentData(JSON.parse(storedContentData));
    }
  }, []);

  useEffect(() => {
    if (contentData && contentData.length > 0) {
      setContentId(contentData[dataIndex]?.id);
    }
  }, [contentData, dataIndex]);

  useEffect(() => {
    if (contentResult.result) {
      const value = contentResult.result.data.filter(
        (entry: any) => entry.contentType !== null
      );
      const ids = value.map((item: { id: string }) => item.id);
      setContentData(value);
      setDataIndex(0);
      const nextIndex = 1;
      const dataIndex = 0;
      localStorage.setItem(
        localStorageLiterals.dataIndex,
        dataIndex.toString()
      );
      localStorage.setItem(
        localStorageLiterals.nextDataIndex,
        nextIndex.toString()
      );
      localStorage.setItem(
        localStorageLiterals.contentData,
        JSON.stringify(value)
      );
      localStorage.setItem(
        localStorageLiterals.bulkContent,
        JSON.stringify(ids)
      );
    }
  }, [contentResult.result]);

  useEffect(() => {
    if (dataIndex) {
      localStorage.setItem(
        localStorageLiterals.dataIndex,
        dataIndex.toString()
      );
    }
  }, [dataIndex]);

  const handleNextButtonClick = () => {
    setDataIndex((prevIndex) => prevIndex + 1);
    navigate("/feedback");
  };

  const handlePreviousButtonClick = () => {
    setDataIndex((prevIndex) => prevIndex - 1);
  };

  useEffect(() => {
    if (contentResult.result && contentResult.result.isLast) {
      localStorage.setItem("isLast", true.toString());
      localStorage.setItem(localStorageLiterals.noContent, false.toString());
    }
  }, [contentResult.result]);


  useEffect(() => {
    if (contentData.length > 0 && dataIndex === contentData.length) {
      navigate("/endpage");
      localStorage.removeItem(localStorageLiterals.contentData);
      localStorage.removeItem(localStorageLiterals.dataIndex);
    }
  }, [dataIndex, contentData]);

  const contentUrl = `${contentData[dataIndex]?.contentUrl}?userId=${userId}&contentId=${contentId}`;

  return (
    <>
      {contentResult.isLoading && <LoaderComponent />}
      {userId && contentId && contentData[dataIndex] && (
        <ContentComponent
          onNextClick={handleNextButtonClick}
          onPreviousClick={handlePreviousButtonClick}
          contentUrl={contentUrl}
          contentData={contentData[dataIndex]}
          canGoNext={dataIndex < contentData.length}
          canGoPrevious={dataIndex !== 0}
        />
      )}
    </>
  );
};
