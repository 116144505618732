import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ConditionComponent } from "../../components/EndPage/Condition";
import { localStorageLiterals } from "../../Constants";
import { useAsync } from "../../hooks/use-async";
import {
  BulkUserRequestDTO,
  BulkUserResponseDTO,
  bulkFeedbackApiPath,
} from "../../dto/bulkFeedback";
import { ApiClientContext } from "../../context/ApiClientContext";
import { AxiosResponse } from "axios";

export const ConditionContainer: React.FC = () => {
  const apiClient = useContext(ApiClientContext)!;
  const navigate = useNavigate();
  const [condition, setCondition] = useState<string[]>([]);
  const [enableContinueButton, setEnableContinueButton] = useState(false);
  const [checkboxClicked, setCheckboxClicked] = useState(false);
  const [selectedRange, setSelectedRange] = useState<number | number[]>(2);
  const [contentIds, setContentIds] = useState<string[]>([]);
  const [isContinue, setIsContinue] = useState(false);
  const [userId, setUserId] = useState<string | null>(null);
  const [isLast, setIsLast] = useState(false);
  const [feedback, setFeedback] = useState("");

  const [result, setResult] = useAsync<BulkUserResponseDTO>({
    fn: async (data: BulkUserRequestDTO) => {
      if (data) {
        const result = await apiClient.post<
          BulkUserRequestDTO,
          AxiosResponse<BulkUserRequestDTO, any>,
          any
        >(bulkFeedbackApiPath, JSON.stringify(data), {
          headers: {
            "Content-Type": "application/json",
          },
        });
        return result.data as any;
      }
    },
  });

  useEffect(() => {
    const storedFeedback = localStorage.getItem(
      localStorageLiterals.experience
    );
    const storedContentIds = localStorage.getItem(
      localStorageLiterals.bulkContent
    );
    const storedNoContent = localStorage.getItem("isLast");
    const storedUserId = localStorage.getItem(localStorageLiterals.userId);
    if (storedUserId) {
      setUserId(storedUserId);
    }
    if (storedNoContent) {
      setIsLast(storedNoContent === "true" ? true : false);
    }
    if (storedContentIds) {
      const parsedIds = JSON.parse(storedContentIds);
      setContentIds(parsedIds);
    }
    if (storedFeedback) {
      setFeedback(storedFeedback);
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (isContinue && enableContinueButton && userId) {
        const dataValue: BulkUserRequestDTO = {
          comment: feedback,
          contentIds: contentIds,
          condition: condition.join(","),
          isFinalContent: isLast,
          userId: userId,
        };
        await setResult(dataValue);
      }
      setIsContinue(false);
    })();
  }, [
    isContinue,
    enableContinueButton,
    userId,
    feedback,
    contentIds,
    isLast,
    condition,
    checkboxClicked,
  ]);

  useEffect(() => {
    if (result.result && result.result.status == 200) {
      localStorage.setItem(localStorageLiterals.condition, true.toString());
      localStorage.removeItem(localStorageLiterals.experience);
      localStorage.removeItem(localStorageLiterals.bulkContent);
      navigate("/thankyou");
    }
  }, [result.result]);

  const handleBackClick = () => {
    navigate("/experience");
  };
  const handleContinueClick = () => {
    setIsContinue(true);
  };

  const handleCheckboxClick = () => {
    setCheckboxClicked(!checkboxClicked);
  };
  useEffect(() => {
    if (condition.length > 0 || checkboxClicked) {
      setEnableContinueButton(true);
    } else {
      setEnableContinueButton(false);
    }
    if (checkboxClicked) {
      setCondition([]);
    }
  }, [condition, checkboxClicked]);
  return (
    <ConditionComponent
      enableContinueButton={enableContinueButton}
      checkboxClicked={checkboxClicked}
      condition={condition}
      selectedRange={+selectedRange}
      handleRangeClick={setSelectedRange}
      onConditionChange={setCondition}
      handleBackClick={handleBackClick}
      handleContinueClick={handleContinueClick}
      handleCheckboxClick={handleCheckboxClick}
      conditionOptions={[
        "Depression",
        "Anxiety",
        "Obsessive-Compulsive Disorder",
        "Other",
        "None",
      ]}
    />
  );
};
