import { useContext, useEffect, useState } from "react";
import { AgeComponent } from "../components/Age";
import { useNavigate } from "react-router-dom";
import {
  Details,
  RegisterUserContext,
  RegisterUserContextProps,
} from "../common/RegisterUserContext";
import { localStorageLiterals } from "../Constants";

export const initialRegisterUserData: Details = {
  age: 0,
  name: "",
  gender: "",
  location: "",
  happyScale: 0,
  energizedScale: 0,
  calmScale: 0,
};

export const AgeContainer: React.FC = () => {
  const navigate = useNavigate();
  const [enableContinueButton, setEnableContinueButton] = useState(false);
  const [selectedRange, setSelectedRange] = useState<number | number[]>(2);

  const { registerUserDetails, setRegisterUserDetails } = useContext(
    RegisterUserContext
  ) as RegisterUserContextProps;

  const onAgeChange = (value: number) => {
    setRegisterUserDetails({ ...registerUserDetails, age: value });
  };

  const handleBackClick = () => {
    navigate("/name");
  };

  const handleContinueClick = () => {
    const registerUserData: Details = {
      ...registerUserDetails,
      age: registerUserDetails.age,
    };
    localStorage.setItem(
      localStorageLiterals.registerUser,
      JSON.stringify(registerUserData)
    );
    navigate("/gender");
  };

  useEffect(() => {
    if (
      !isNaN(registerUserDetails.age) &&
      Number.isInteger(registerUserDetails.age) &&
      registerUserDetails.age !== 0 &&
      registerUserDetails.age < 100
    ) {
      setEnableContinueButton(true);
    } else {
      setEnableContinueButton(false);
    }
  }, [registerUserDetails.age]);

  return (
    <AgeComponent
      enableContinueButton={enableContinueButton}
      age={registerUserDetails.age || 0}
      selectedRange={+selectedRange}
      handleRangeClick={setSelectedRange}
      onAgeChange={onAgeChange}
      handleBackClick={handleBackClick}
      handleContinueClick={handleContinueClick}
    />
  );
};
