import { Button, Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import { ContentResponseDTO } from "../dto/content";
import ResponsiveVideo from "./ResponsiveVideo/ResponsiveVideo";
import WebGLGame from "./WebGLGame/WebGLGame";
import ArrowLeft from "../asset/ArrowLeft.svg";
import ArrowRight from "../asset/ArrowRight.svg";
import Sketch from "./SketchPad/Sketch";
import { deviceType } from "react-device-detect";
import { useEffect } from "react";

const button: SxProps<Theme> = {
  flex: 1,
  position: "absolute",
  top: 20,
  left: 2,
  height: deviceType === "mobile" ? "5px" : "10px",
  width: deviceType === "mobile" ? "5px" : "20px",
  fontFamily: "DM Sans",
  textTransform: "none",
  alignSelf: "center",
  backgroundColor: "#FFFFFF",
  textAlign: "center",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "14px",
  outline: "none",
  color: "#000",
  ":hover": {
    backgroundColor: "#FFFFFF",
    color: "#000",
  },
};



const buttonNext: SxProps<Theme> = {
  flex: 1,
  position: "absolute",
  top: 20,
  left: 64,
  height: deviceType === "mobile" ? "5px" : "10px",
  width: deviceType === "mobile" ? "5px" : "20px",
  fontFamily: "DM Sans",
  textTransform: "none",
  alignSelf: "center",
  backgroundColor: "#FFFFFF",
  textAlign: "center",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "14px",
  outline: "none",
  color: "#000",
  ":hover": {
    backgroundColor: "#FFFFFF",
    color: "#000",
  },
};

type ContentComponentProps = {
  onNextClick: () => void;
  onPreviousClick: () => void;
  contentData: ContentResponseDTO["data"][number] | undefined;
  canGoNext: boolean;
  canGoPrevious: boolean;
  contentUrl: string;
};

export const ContentComponent: React.FC<ContentComponentProps> = ({
  onNextClick,
  onPreviousClick,
  contentData,
  canGoNext,
  canGoPrevious,
  contentUrl
}) => {

  useEffect(() => {
    if (contentData && contentData.contentType === "GAME") {
      window.location.href = contentUrl;
    }
  }, [contentData, contentUrl]); 


  return (
    <>
    <div>
      {/* <h3>{contentData?.contentUrl}</h3> */}
    </div>
      {contentData && (
        <>
          {contentData.contentType === "VIDEO" && (
            <ResponsiveVideo videoUrl={contentUrl} />
          )}
          {contentData.contentType === "DRAW" && <Sketch />}
          {/* <Button
            sx={button}
            onClick={onPreviousClick}
            disabled={!canGoPrevious}
          >
            <img src={ArrowLeft} alt="previous" />
          </Button>
          <Button sx={buttonNext} onClick={onNextClick} disabled={!canGoNext}>
            <img src={ArrowRight} alt="next" />
          </Button> */}
        </>
      )}
    </>
  );
};
