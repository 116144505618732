import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HomeComponent } from "../components/Home";
import { localStorageLiterals } from "../Constants";

export const HomeContainer: React.FC = () => {
  const navigate = useNavigate();
  const [showOnBoarding, setShowOnBoarding] = useState<boolean>(false);
  const [userId, setUserId] = useState("");
  const [onClick, setOnClick] = useState(false);

  const handleNextButtonClick = () => {
    setOnClick(true);
  };

  useEffect(() => {
    const userId = localStorage.getItem(localStorageLiterals.userId);
    if (userId) {
      setUserId(userId);
    }
  }, []);

  useEffect(() => {
    if (onClick && userId) {
      navigate("/content");
    }
    if (onClick && !userId) {
      navigate("/name");
    }
  }, [onClick, userId]);

  return (
    <HomeComponent
      showOnBoarding={showOnBoarding}
      handleNextButtonClick={handleNextButtonClick}
    />
  );
};
