import React, { useEffect, useState } from "react";
import { Box, Button, Theme, Typography } from "@mui/material";
import { isMobile, deviceType } from "react-device-detect";
import { SxProps } from "@mui/system";
import { messages } from "../../Constants";
import { LoaderComponent } from "../Loader";
import Slider from "rc-slider";
const container: SxProps<Theme> = {
  width: "100vw",
  height: isMobile ? "auto" : "100vh",
  minHeight: isMobile ? "100vh" : "auto",
  background: "#FFF",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  boxSizing: "border-box",
  overflowY: "auto",
  color: "#4D2D66",
};

const leftInnerContainer: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  alignContent: "center",
  alignItems: "center",
  justifyContent: "center",
  width: isMobile ? "80%" : "40%",
};

const title: SxProps<Theme> = {
  fontFamily: "DM Sans Light",
  fontSize: isMobile ? (deviceType === "tablet" ? "28px" : "24px") : "32px",
  color: "#4D2D66",
  lineHeight: isMobile
    ? deviceType === "tablet"
      ? "35px"
      : "30px"
    : "41.66px",
  textAlign: "center",
  marginBottom: "15px",
};

const optionsContainer: SxProps<Theme> = {
  display: "flex",
  flexDirection: "row",
  width: "100%",
  justifyContent: "space-between",
  marginBottom: !isMobile ? "15px" : deviceType === "tablet" ? "15px" : "15px",
  marginTop: !isMobile ? "15px" : deviceType === "tablet" ? "15px" : "15px",
};

const optionStyle: React.CSSProperties = {
  textAlign: "center",
  cursor: "pointer",
  borderBottom: "2px solid transparent",
  fontSize: "14px",
};

const innerContainer: SxProps<Theme> = {
  marginTop: "20px",
  display: "flex",
  alignContent: "space-between",
  width: "100%",
  paddingBottom: "5px",
  textAlign: "left",
  flexDirection: deviceType === "mobile" ? "column-reverse" : "row",
};

const continueButton: SxProps<Theme> = {
  fontFamily: "DM Sans",
  textTransform: "none",
  height: "50px",
  width: "100%",
  alignSelf: "center",
  backgroundColor: "#BF50B2",
  textAlign: "center",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "14px",
  letterSpacing: "1.5px",
  border: "none",
  borderRadius: "56px",
  outline: "none",
  marginRight: deviceType === "mobile" ? 0 : "20px",
  marginLeft: deviceType === "mobile" ? 0 : "20px",
  color: "white",
  ":hover": {
    backgroundColor: "#BF50B2",
    color: "white",
  },
};

const disableContinueButton: SxProps<Theme> = {
  fontFamily: "DM Sans",
  textTransform: "none",
  height: "50px",
  width: "100%",
  alignSelf: "center",
  backgroundColor: "rgba(191, 80, 178, 0.3)",
  textAlign: "center",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "14px",
  letterSpacing: "1.5px",
  border: "none",
  borderRadius: "56px",
  outline: "none",
  color: "white",
  marginRight: deviceType === "mobile" ? 0 : "20px",
  marginLeft: deviceType === "mobile" ? 0 : "20px",
  ":hover": {
    backgroundColor: "rgba(191, 80, 178, 0.3)",
    color: "white",
  },
};

const backButton: SxProps<Theme> = {
  fontFamily: "DM Sans",
  textTransform: "none",
  height: "50px",
  width: "100%",
  alignSelf: "center",
  backgroundColor: "#FFFFFF",
  textAlign: "center",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "14px",
  letterSpacing: "1.5px",
  border: "1px solid #000", // Specify both border style and color
  borderRadius: "56px",
  outline: "none",
  color: "#000",
  marginTop: deviceType === "mobile" ? "15px" : 0,
  marginRight: deviceType === "mobile" ? 0 : "20px",
  marginLeft: deviceType === "mobile" ? 0 : "20px",
  ":hover": {
    backgroundColor: "#FFFFFF",
    color: "#000",
  },
};

const trackStyles: React.CSSProperties = {
  backgroundColor: "#BF50B2",
  height: "8px",
};

const disableTrackStyles: React.CSSProperties = {
  backgroundColor: "#909090",
  height: "8px",
};

const disableHandleStyles: React.CSSProperties = {
  borderColor: "#909090",
  backgroundColor: "white",
  height: "20px",
  width: "20px",
  boxShadow: "none",
};

const handleStyles: React.CSSProperties = {
  borderColor: "#BF50B2",
  backgroundColor: "white",
  height: "20px",
  width: "20px",
  boxShadow: "none",
};

const railStyles: React.CSSProperties = {
  height: "8px",
};

const activeHandleStyle: React.CSSProperties = {
  boxShadow: "none",
};

const markStyle: React.CSSProperties = {
  fontSize: "14px",
  color: "black",
  position: "absolute",
  top: 0,
  lineHeight: "1px",
};

export type GameFeedbackProps = {
  scales: string[];
  isLoading: boolean;
  enableContinueButton: boolean;
  selectedRange: number;
  selectBoredEngagedRange: number;
  selectedFrustratedCalmRange: number;
  selectedFocusedScaleRange: number;
  selectedAlertScaleRange: number;
  selectedRestlessScaleRange: number;
  isRangeClicked: boolean;
  isFocusedScaleRangeClicked: boolean;
  isAlertScaleRangeClicked: boolean;
  isBoredEngagedRangeClicked: boolean;
  isFrustratedCalmRangeClicked: boolean;
  isRestlessRangeClicked: boolean;
  contentType?: string;
  handleRangeInitialClick(): void;
  handleBoredEngagedRangeInitialClick(): void;
  handleFrustratedCalmRangeInitialClick(): void;
  handleAlertScaleRangeInitialClick(): void;
  handleFocusedScaleRangeInitialClick(): void;
  handleRestlessScaleRangeInitialClick(): void;
  handleBoredEngagedRangeClick(
    selectedBoredEngagedRange: number | number[]
  ): void;
  handleRangeClick(selectedRange: number | number[]): void;
  handleRestlessScaleRange(selectedRestlessRange: number | number[]): void;
  handleFrustratedCalmRangeClick(
    selectedFrustratedCalmRange: number | number[]
  ): void;
  handleFocusedScaleClick(selectedFocusedScaleRange: number | number[]): void;
  handleAlertScaleClick(selectedAlertScaleRange: number | number[]): void;
  handleBackClick(): void;
  handleContinueClick(): void;
};

export const GameFeedbackComponent: React.FC<GameFeedbackProps> = ({
  isLoading,
  contentType,
  enableContinueButton,
  scales,
  selectedRange,
  selectBoredEngagedRange,
  selectedFrustratedCalmRange,
  selectedAlertScaleRange,
  selectedFocusedScaleRange,
  selectedRestlessScaleRange,
  isAlertScaleRangeClicked,
  isBoredEngagedRangeClicked,
  isFocusedScaleRangeClicked,
  isFrustratedCalmRangeClicked,
  isRangeClicked,
  isRestlessRangeClicked,
  handleAlertScaleRangeInitialClick,
  handleBoredEngagedRangeInitialClick,
  handleFocusedScaleRangeInitialClick,
  handleFrustratedCalmRangeInitialClick,
  handleRangeInitialClick,
  handleRestlessScaleRangeInitialClick,
  handleRestlessScaleRange,
  handleAlertScaleClick,
  handleFocusedScaleClick,
  handleBackClick,
  handleContinueClick,
  handleRangeClick,
  handleBoredEngagedRangeClick,
  handleFrustratedCalmRangeClick,
}) => {
  const showFocused = scales.includes("focussedScale");
  const showAlert = scales.includes("alertScale");
  const showEnergized = scales.includes("energizedScale");
  const showHappy = scales.includes("happyScale");
  const showCalm = scales.includes("calmScale");
  const showRestless = scales.includes("restlessScale");

  return (
    <>
      {isLoading ? (
        <LoaderComponent />
      ) : (
        <Box sx={container}>
          <Box sx={leftInnerContainer}>
            <Box
              sx={{
                ...leftInnerContainer,
                width: deviceType === "mobile" ? "100%" : "80%",
                flex: 1,
              }}
            >
              <Typography sx={title}>{messages.lastGame}</Typography>
              {(showHappy || scales.length === 0) && (
                <>
                  <Slider
                    min={-3}
                    max={3}
                    value={selectedRange}
                    trackStyle={
                      !isRangeClicked ? disableTrackStyles : trackStyles
                    }
                    handleStyle={
                      !isRangeClicked ? disableHandleStyles : handleStyles
                    }
                    railStyle={railStyles}
                    defaultValue={0}
                    activeDotStyle={activeHandleStyle}
                    marks={{
                      "-3": <span style={markStyle}> |</span>,
                      "-2": <span style={markStyle}>|</span>,
                      "-1": <span style={markStyle}>|</span>,
                      "0": <span style={markStyle}>|</span>,
                      "1": <span style={markStyle}>|</span>,
                      "2": <span style={markStyle}>|</span>,
                      "3": <span style={markStyle}>| </span>,
                    }}
                    dotStyle={{ display: "none" }}
                    onChange={handleRangeClick}
                    onChangeComplete={handleRangeInitialClick}
                  />
                  <Box sx={optionsContainer}>
                    <div
                      style={optionStyle}
                      onClick={() => handleRangeClick(-3)}
                    >
                      Bored
                    </div>
                    <div
                      style={optionStyle}
                      onClick={() => handleRangeClick(3)}
                    >
                      Engaged
                    </div>
                  </Box>
                </>
              )}
              {showCalm && (
                <>
                  <Slider
                    min={-3}
                    max={3}
                    value={selectBoredEngagedRange}
                    trackStyle={
                      !isBoredEngagedRangeClicked
                        ? disableTrackStyles
                        : trackStyles
                    }
                    handleStyle={
                      !isBoredEngagedRangeClicked
                        ? disableHandleStyles
                        : handleStyles
                    }
                    railStyle={railStyles}
                    defaultValue={0}
                    activeDotStyle={activeHandleStyle}
                    marks={{
                      "-3": <span style={markStyle}> |</span>,
                      "-2": <span style={markStyle}>|</span>,
                      "-1": <span style={markStyle}>|</span>,
                      "0": <span style={markStyle}>|</span>,
                      "1": <span style={markStyle}>|</span>,
                      "2": <span style={markStyle}>|</span>,
                      "3": <span style={markStyle}>| </span>,
                    }}
                    dotStyle={{ display: "none" }}
                    onChange={handleBoredEngagedRangeClick}
                    onChangeComplete={handleBoredEngagedRangeInitialClick}
                  />
                  <Box sx={optionsContainer}>
                    <div
                      style={optionStyle}
                      onClick={() => handleBoredEngagedRangeClick(-3)}
                    >
                      Anxious/Tense
                    </div>
                    <div
                      style={optionStyle}
                      onClick={() => handleBoredEngagedRangeClick(3)}
                    >
                      Calm/Relaxed
                    </div>
                  </Box>
                </>
              )}
              {showEnergized && (
                <>
                  <Slider
                    min={-3}
                    max={3}
                    value={selectedFrustratedCalmRange}
                    trackStyle={
                      !isFrustratedCalmRangeClicked
                        ? disableTrackStyles
                        : trackStyles
                    }
                    handleStyle={
                      !isFrustratedCalmRangeClicked
                        ? disableHandleStyles
                        : handleStyles
                    }
                    railStyle={railStyles}
                    defaultValue={0}
                    activeDotStyle={activeHandleStyle}
                    marks={{
                      "-3": <span style={markStyle}> |</span>,
                      "-2": <span style={markStyle}>|</span>,
                      "-1": <span style={markStyle}>|</span>,
                      "0": <span style={markStyle}>|</span>,
                      "1": <span style={markStyle}>|</span>,
                      "2": <span style={markStyle}>|</span>,
                      "3": <span style={markStyle}>| </span>,
                    }}
                    dotStyle={{ display: "none" }}
                    onChange={handleFrustratedCalmRangeClick}
                    onChangeComplete={handleFrustratedCalmRangeInitialClick}
                  />
                  <Box sx={optionsContainer}>
                    <div
                      style={optionStyle}
                      onClick={() => handleFrustratedCalmRangeClick(-3)}
                    >
                      Frustrated
                    </div>
                    <div
                      style={optionStyle}
                      onClick={() => handleFrustratedCalmRangeClick(3)}
                    >
                      Calm/Relaxed
                    </div>
                  </Box>
                </>
              )}
              {showFocused && (
                <>
                  <Slider
                    min={-3}
                    max={3}
                    value={selectedFocusedScaleRange}
                    trackStyle={
                      !isFocusedScaleRangeClicked
                        ? disableTrackStyles
                        : trackStyles
                    }
                    handleStyle={
                      !isFocusedScaleRangeClicked
                        ? disableHandleStyles
                        : handleStyles
                    }
                    railStyle={railStyles}
                    defaultValue={0}
                    activeDotStyle={activeHandleStyle}
                    marks={{
                      "-3": <span style={markStyle}> |</span>,
                      "-2": <span style={markStyle}>|</span>,
                      "-1": <span style={markStyle}>|</span>,
                      "0": <span style={markStyle}>|</span>,
                      "1": <span style={markStyle}>|</span>,
                      "2": <span style={markStyle}>|</span>,
                      "3": <span style={markStyle}>| </span>,
                    }}
                    dotStyle={{ display: "none" }}
                    onChange={handleFocusedScaleClick}
                    onChangeComplete={handleFocusedScaleRangeInitialClick}
                  />
                  <Box sx={optionsContainer}>
                    <div
                      style={optionStyle}
                      onClick={() => handleFocusedScaleClick(-3)}
                    >
                      Distracted
                    </div>
                    <div
                      style={optionStyle}
                      onClick={() => handleFocusedScaleClick(3)}
                    >
                      Focused
                    </div>
                  </Box>
                </>
              )}
              {showAlert && (
                <>
                  <Slider
                    min={-3}
                    max={3}
                    value={selectedAlertScaleRange}
                    trackStyle={
                      !isAlertScaleRangeClicked
                        ? disableTrackStyles
                        : trackStyles
                    }
                    handleStyle={
                      !isAlertScaleRangeClicked
                        ? disableHandleStyles
                        : handleStyles
                    }
                    railStyle={railStyles}
                    defaultValue={0}
                    activeDotStyle={activeHandleStyle}
                    marks={{
                      "-3": <span style={markStyle}> |</span>,
                      "-2": <span style={markStyle}>|</span>,
                      "-1": <span style={markStyle}>|</span>,
                      "0": <span style={markStyle}>|</span>,
                      "1": <span style={markStyle}>|</span>,
                      "2": <span style={markStyle}>|</span>,
                      "3": <span style={markStyle}>| </span>,
                    }}
                    dotStyle={{ display: "none" }}
                    onChange={handleAlertScaleClick}
                    onChangeComplete={handleAlertScaleRangeInitialClick}
                  />
                  <Box sx={optionsContainer}>
                    <div
                      style={optionStyle}
                      onClick={() => handleAlertScaleClick(-3)}
                    >
                      Tired
                    </div>
                    <div
                      style={optionStyle}
                      onClick={() => handleAlertScaleClick(3)}
                    >
                      Alert
                    </div>
                  </Box>
                </>
              )}
              {showRestless && (
                <>
                  <Slider
                    min={-3}
                    max={3}
                    value={selectedRestlessScaleRange}
                    trackStyle={
                      !isRestlessRangeClicked ? disableTrackStyles : trackStyles
                    }
                    handleStyle={
                      !isRestlessRangeClicked
                        ? disableHandleStyles
                        : handleStyles
                    }
                    railStyle={railStyles}
                    defaultValue={0}
                    activeDotStyle={activeHandleStyle}
                    marks={{
                      "-3": <span style={markStyle}> |</span>,
                      "-2": <span style={markStyle}>|</span>,
                      "-1": <span style={markStyle}>|</span>,
                      "0": <span style={markStyle}>|</span>,
                      "1": <span style={markStyle}>|</span>,
                      "2": <span style={markStyle}>|</span>,
                      "3": <span style={markStyle}>| </span>,
                    }}
                    dotStyle={{ display: "none" }}
                    onChange={handleRestlessScaleRange}
                    onChangeComplete={handleRestlessScaleRangeInitialClick}
                  />
                  <Box sx={optionsContainer}>
                    <div
                      style={optionStyle}
                      onClick={() => handleRestlessScaleRange(-3)}
                    >
                      Restless/Alert
                    </div>
                    <div
                      style={optionStyle}
                      onClick={() => handleRestlessScaleRange(3)}
                    >
                      Sleepy
                    </div>
                  </Box>
                </>
              )}
              <Box sx={innerContainer}>
                <Button sx={backButton} onClick={handleBackClick}>
                  {messages.back}
                </Button>
                <Button
                  sx={
                    enableContinueButton
                      ? continueButton
                      : disableContinueButton
                  }
                  disabled={!enableContinueButton}
                  onClick={() => handleContinueClick()}
                >
                  {messages.submitFeedback}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};
