import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GameFeedbackComponent } from "../../components/Feedback/GameFeedback";
import {
  SubmitFeedbackRequestDTO,
  SubmitFeedbackResponseDTO,
  submitFeedbackApiPath,
} from "../../dto/submitFeedback";
import { localStorageLiterals } from "../../Constants";
import { AxiosResponse } from "axios";
import { ApiClientContext } from "../../context/ApiClientContext";
import { useAsync } from "../../hooks/use-async";
import { REACT_APP_IS_GAME_CODE } from "../../env";

export const GameFeedbackContainer: React.FC = () => {
  const navigate = useNavigate();
  const apiClient = useContext(ApiClientContext)!;
  const [selectedRange, setSelectedRange] = useState<number | number[]>(4);
  const [selectedFocusedScaleRange, setFocusedScaleSelectedRange] = useState<
    number | number[]
  >(4);
  const [selectedAlertScaleRange, setAlertScaleSelectedRange] = useState<
    number | number[]
  >(4);
  const [selectedBoredEngagedRange, setBoredEngagedSelectedRange] = useState<
    number | number[]
  >(4);
  const [selectedFrustratedCalmRange, setFrustratedCalmSelectedRange] =
    useState<number | number[]>(4);
  const [selectedRestlessRange, setSelectedRestlessRange] = useState<
    number | number[]
  >(4);

  const [isRangeClicked, setIsRangeClicked] = useState(false);
  const [isFocusedScaleRangeClicked, setIsFocusedScaleRangeClicked] =
    useState(false);
  const [isAlertScaleRangeClicked, setIsAlertScaleRangeClicked] =
    useState(false);
  const [isBoredEngagedRangeClicked, setIsBoredEngagedRangeClicked] =
    useState(false);
  const [isFrustratedCalmRangeClicked, setIsFrustratedCalmRangeClicked] =
    useState(false);
  const [isRestlessRangeClicked, setIsRestlessRangeClicked] = useState(false);

  const [enableContinueButton, setEnableContinueButton] = useState(true);
  const [submitFeedback, setSubmitFeedbackData] =
    useState<SubmitFeedbackRequestDTO>();
  const [isSubmitClick, setIsSubmitClick] = useState(false);
  const [userId, setUserId] = useState("");

  const [contentData, setContentData] = useState<any[]>([]);
  const [dataIndex, setDataIndex] = useState<number>(0);
  const [scales, setScales] = useState([
    "happyScale",
    "calmScale",
    "energizedScale",
  ]);

  useEffect(() => {
    const storedContentData = localStorage.getItem(
      localStorageLiterals.contentData
    );
    if (storedContentData) {
      setContentData(JSON.parse(storedContentData));
    }

    const storedDataIndex = localStorage.getItem(
      localStorageLiterals.dataIndex
    );
    if (storedDataIndex) {
      setDataIndex(parseInt(storedDataIndex, 10));
    }
  }, []);

  const [result, setResult] = useAsync<SubmitFeedbackResponseDTO>({
    fn: async (data: SubmitFeedbackRequestDTO) => {
      const result = await apiClient.post<
        SubmitFeedbackRequestDTO,
        AxiosResponse<SubmitFeedbackResponseDTO, any>,
        any
      >(submitFeedbackApiPath, data);
      return result.data;
    },
  });

  useEffect(() => {
    const userId = localStorage.getItem(localStorageLiterals.userId);
    if (userId) {
      setUserId(userId);
    }
  }, []);

  useEffect(() => {
    const submitFeedbackData = localStorage.getItem(
      localStorageLiterals.submitFeedback
    );
    if (submitFeedbackData) {
      setSubmitFeedbackData(JSON.parse(submitFeedbackData));
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (isSubmitClick && submitFeedback) {
        const contentId = contentData[dataIndex].id;
        const dataValue: SubmitFeedbackRequestDTO = {
          like: submitFeedback.like,
          comment: submitFeedback.comment,
          reaction: submitFeedback.reaction,
          userId: userId,
          contentId: contentId,
          happyScale: +selectedBoredEngagedRange,
          calmScale: +selectedFrustratedCalmRange,
          energizedScale: +selectedRange,
          focusedScale: +selectedFocusedScaleRange,
          alertScale: +selectedAlertScaleRange,
          restlessScale: +selectedRestlessRange,
        };
        setResult(dataValue);
        if (REACT_APP_IS_GAME_CODE) {
          const parentWindow = window.parent;
          if (parentWindow) {
            // Redirect to the parent component
            parentWindow.location.href = "/";
          }
        }
      }
    })();
  }, [isSubmitClick, submitFeedback]);

  useEffect(() => {
    if (result.result) {
      if (dataIndex !== contentData.length - 1) {
        const updatedIndex = dataIndex + 1;
        setDataIndex(updatedIndex);
        localStorage.setItem(
          localStorageLiterals.dataIndex,
          updatedIndex.toString()
        );
        localStorage.removeItem(localStorageLiterals.submitFeedback);
        navigate("/content");
      } else {
        if (dataIndex === contentData.length - 1) {
          const updatedIndex = dataIndex + 1;
          setDataIndex(updatedIndex);
          localStorage.setItem(
            localStorageLiterals.dataIndex,
            updatedIndex.toString()
          );
          localStorage.setItem(
            localStorageLiterals.contentData,
            JSON.stringify(["NO DATA"])
          );
          localStorage.removeItem(localStorageLiterals.submitFeedback);
          navigate("/content");
        }
      }
    }
  }, [result.result]);

  const handleBackClick = () => {
    navigate("/feedback");
  };
  const handleContinueClick = () => {
    setIsSubmitClick(true);
  };

  const handleRangeInitialClick = () => {
    setIsRangeClicked(true);
  };

  const handleBoredEngagedRangeInitialClick = () => {
    setIsBoredEngagedRangeClicked(true);
  };

  const handleFrustratedCalmRangeInitialClick = () => {
    setIsFrustratedCalmRangeClicked(true);
  };

  const handleAlertScaleRangeInitialClick = () => {
    setIsAlertScaleRangeClicked(true);
  };

  const handleFocusedScaleRangeInitialClick = () => {
    setIsFocusedScaleRangeClicked(true);
  };

  const handleRestlessScaleRangeInitialClick = () => {
    setIsRestlessRangeClicked(true);
  };

  return (
    <GameFeedbackComponent
      scales={scales}
      isLoading={result.isLoading}
      enableContinueButton={enableContinueButton}
      selectedRange={+selectedRange}
      selectBoredEngagedRange={+selectedBoredEngagedRange}
      selectedFrustratedCalmRange={+selectedFrustratedCalmRange}
      selectedAlertScaleRange={+selectedAlertScaleRange}
      selectedFocusedScaleRange={+selectedFocusedScaleRange}
      selectedRestlessScaleRange={+selectedRestlessRange}
      isAlertScaleRangeClicked={isAlertScaleRangeClicked}
      isBoredEngagedRangeClicked={isBoredEngagedRangeClicked}
      isFocusedScaleRangeClicked={isFocusedScaleRangeClicked}
      isFrustratedCalmRangeClicked={isFrustratedCalmRangeClicked}
      isRangeClicked={isRangeClicked}
      isRestlessRangeClicked={isRestlessRangeClicked}
      handleAlertScaleRangeInitialClick={handleAlertScaleRangeInitialClick}
      handleBoredEngagedRangeInitialClick={handleBoredEngagedRangeInitialClick}
      handleFocusedScaleRangeInitialClick={handleFocusedScaleRangeInitialClick}
      handleFrustratedCalmRangeInitialClick={
        handleFrustratedCalmRangeInitialClick
      }
      handleRangeInitialClick={handleRangeInitialClick}
      handleRestlessScaleRangeInitialClick={
        handleRestlessScaleRangeInitialClick
      }
      handleRestlessScaleRange={setSelectedRestlessRange}
      handleAlertScaleClick={setAlertScaleSelectedRange}
      handleFocusedScaleClick={setFocusedScaleSelectedRange}
      handleBoredEngagedRangeClick={setBoredEngagedSelectedRange}
      handleFrustratedCalmRangeClick={setFrustratedCalmSelectedRange}
      handleRangeClick={setSelectedRange}
      handleBackClick={handleBackClick}
      handleContinueClick={handleContinueClick}
    />
  );
};
