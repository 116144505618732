import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LocationComponent } from "../components/Location";
import {
  Details,
  RegisterUserContext,
  RegisterUserContextProps,
} from "../common/RegisterUserContext";
import { localStorageLiterals } from "../Constants";

export const LocationContainer: React.FC = () => {
  const navigate = useNavigate();
  const [otherLocation, setOtherLocation] = useState("");
  const [enableContinueButton, setEnableContinueButton] = useState(false);
  const [selectedRange, setSelectedRange] = useState<number | number[]>(4);

  const { registerUserDetails, setRegisterUserDetails } = useContext(
    RegisterUserContext
  ) as RegisterUserContextProps;

  const onLocationChange = (value: string) => {
    const uppercaseValue = value.toUpperCase();
    setRegisterUserDetails({
      ...registerUserDetails,
      location: uppercaseValue,
    });
  };

  const handleBackClick = () => {
    navigate("/gender");
  };
  const handleContinueClick = () => {
    const registerUserData: Details = {
      ...registerUserDetails,
      gender: registerUserDetails.gender,
      otherLocation: registerUserDetails.otherLocation,
    };
    localStorage.setItem(
      localStorageLiterals.registerUser,
      JSON.stringify(registerUserData)
    );
    navigate("/feelings");
  };
  useEffect(() => {
    if (
      (registerUserDetails.location &&
        registerUserDetails.location !== "OTHER") ||
      (registerUserDetails.location &&
        registerUserDetails.location === "OTHER" &&
        registerUserDetails.otherLocation &&
        registerUserDetails.otherLocation !== undefined)
    ) {
      setEnableContinueButton(true);
    } else {
      setEnableContinueButton(false);
    }
  }, [registerUserDetails.location, registerUserDetails.otherLocation]);

  const formatLocation = (value: string) => {
    if (!value) return "";
    if (value === "USA") return value;
    const lowercaseValue = value.toLowerCase();
    return lowercaseValue.charAt(0).toUpperCase() + lowercaseValue.slice(1);
  };

  useEffect(() => {
    if (registerUserDetails.location === "OTHER") {
      setRegisterUserDetails({
        ...registerUserDetails,
        otherLocation: otherLocation,
      });
    }
  }, [otherLocation, registerUserDetails.location]);

  return (
    <LocationComponent
      enableContinueButton={enableContinueButton}
      location={formatLocation(registerUserDetails.location)}
      otherLocation={registerUserDetails.otherLocation}
      selectedRange={+selectedRange}
      handleRangeClick={setSelectedRange}
      onLocationChange={onLocationChange}
      handleOtherLocationChange={setOtherLocation}
      handleBackClick={handleBackClick}
      handleContinueClick={handleContinueClick}
      locationOptions={["USA", "Canada", "India", "Other"]}
    />
  );
};
