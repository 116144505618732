import React from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { isMobile, deviceType } from "react-device-detect";
import { SxProps } from "@mui/system";
import { messages } from "../../Constants";
import happy from "../../asset/Happy.svg";
import sad from "../../asset/Sad.svg";
import happyClick from "../../asset/HappyBlue.svg";
import sadClick from "../../asset/SadBlue.svg";

const container: SxProps<Theme> = {
  width: "100vw",
  height: isMobile ? "auto" : "100vh",
  minHeight: isMobile ? "100vh" : "auto",
  background: "#FFF",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  boxSizing: "border-box",
  overflowY: "auto",
  color: "#4D2D66",
};

const title: SxProps<Theme> = {
  fontFamily: "DM Sans Light",
  fontSize: isMobile ? (deviceType === "tablet" ? "28px" : "24px") : "32px",
  color: "#4D2D66",
  lineHeight: isMobile
    ? deviceType === "tablet"
      ? "35px"
      : "30px"
    : "41.66px",
  textAlign: "center",
  marginBottom: "15px",
};

const subTitle: SxProps<Theme> = {
  fontFamily: "DM Sans Light",
  fontSize: isMobile ? (deviceType === "tablet" ? "16px" : "14px") : "16px",
  color: "#4D2D66",
  lineHeight: isMobile
    ? deviceType === "tablet"
      ? "35px"
      : "30px"
    : "41.66px",
  textAlign: "left",
  marginBottom: "10px",
};

const leftInnerContainer: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  alignContent: "center",
  alignItems: "center",
  justifyContent: "center",
  width: isMobile ? "80%" : "40%",
};

const innerContainer: SxProps<Theme> = {
  display: "flex",
  justifyContent: "center",
  width: "100%",
  paddingBottom: "5px",
  flexDirection: "row",
};

const message: SxProps<Theme> = {
  fontFamily: "DM Sans Light",
  fontSize: isMobile ? (deviceType === "tablet" ? "20px" : "16px") : "20px",
  color: "#4D2D66",
  lineHeight: isMobile ? (deviceType === "tablet" ? "20px" : "18px") : "22px",
  padding: "10px",
};

const messageThoughts: SxProps<Theme> = {
  fontFamily: "DM Sans Light",
  fontSize: isMobile ? (deviceType === "tablet" ? "18px" : "14px") : "18px",
  color: "#4D2D66",
  paddingTop: "10px",
};

const continueButton: SxProps<Theme> = {
  fontFamily: "DM Sans",
  textTransform: "none",
  height: "50px",
  width: "70%",
  alignSelf: "center",
  backgroundColor: "#BF50B2",
  textAlign: "center",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "14px",
  letterSpacing: "1.5px",
  border: "none",
  borderRadius: "56px",
  outline: "none",
  color: "white",
  ":hover": {
    backgroundColor: "#BF50B2",
    color: "white",
  },
};

const disableContinueButton: SxProps<Theme> = {
  fontFamily: "DM Sans",
  textTransform: "none",
  height: "50px",
  width: "70%",
  alignSelf: "center",
  backgroundColor: "rgba(191, 80, 178, 0.3)",
  textAlign: "center",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "14px",
  letterSpacing: "1.5px",
  border: "none",
  borderRadius: "56px",
  outline: "none",
  color: "white",
  ":hover": {
    backgroundColor: "rgba(191, 80, 178, 0.3)",
    color: "white",
  },
};

const button: SxProps<Theme> = {
  fontFamily: "DM Sans",
  textTransform: "none",
  height: "60px",
  width: "50px",
  alignSelf: "center",
  backgroundColor: "transparent",
  textAlign: "center",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "14px",
  letterSpacing: "1.5px",
  border: "none",
  borderRadius: "106px",
  outline: "none",
  marginTop: "15px",
  color: "white",
  ":hover": {
    backgroundColor: "#transparent",
    color: "white",
  },
};

const fieldBox: SxProps<Theme> = {
  width: "100%",
  marginBottom: "6px",
  marginTop: "6px",
  padding: "10px",
  border: "1px solid #CFCFCF",
  borderRadius: "100px",
};

const textBox: SxProps<Theme> = {
  width: "100%",
  marginBottom: "14px",
  "& .MuiOutlinedInput-root": {
    borderRadius: "100px",
    background: "#FFFFFF",
    padding: "5px",
  },
  "& .MuiOutlinedInput-input": {
    background: "#FFFFFF",
  },
  "& .MuiOutlinedInput-root:hover, & .Mui-focused .MuiOutlinedInput-root.Mui-focused":
    {
      borderColor: "#CFCFCF",
    },
};

const fieldBoxThought: SxProps<Theme> = {
  width: "100%",
  marginBottom: "30px",
  marginTop: "16px",
  "& .MuiOutlinedInput-root": {
    borderRadius: "32px",
    background: "#FFFFFF",
    padding: "10px",
  },
  "& .MuiOutlinedInput-input": {
    background: "#FFFFFF",
    padding: "10px",
  },
  "& .MuiOutlinedInput-root:hover, & .Mui-focused .MuiOutlinedInput-root.Mui-focused":
    {
      borderColor: "#CFCFCF",
      padding: "10px",
    },
};

function BpCheckbox(props: any) {
  return <Checkbox disableRipple color="secondary" {...props} />;
}

export type Smiley = {
  isHappySmileyClicked: boolean;
  isSadSmileyClicked: boolean;
};

export type ExperienceOption = {
  value: string;
  label: string;
};

export type FeedbackProps = {
  smiley: Smiley;
  experienceOptions: ExperienceOption[];
  enableContinueButton: boolean;
  experience: string[];
  experienceFeedback?: string;
  thoughts?: string;
  onExperienceFeedbackChange(experienceFeedback: string): void;
  onExperienceChange(experienceOption: string[]): void;
  handleHappyClick(): void;
  handleSadClick(): void;
  handleContinueClick(): void;
  onThoughtChange(thoughts: string): void;
};

export const FeedbackComponent: React.FC<FeedbackProps> = ({
  smiley,
  experienceOptions,
  experience,
  enableContinueButton,
  experienceFeedback,
  thoughts,
  onThoughtChange,
  onExperienceFeedbackChange,
  handleContinueClick,
  onExperienceChange,
  handleSadClick,
  handleHappyClick,
}) => {
  const experienceStyling = (selectedExperience: string) => {
    const defaultStyles = { ...fieldBox };

    if (experience.includes(selectedExperience)) {
      return {
        ...defaultStyles,
        border: "1px solid #4D2D66",
        backgroundColor: "#F0E4EE",
      };
    } else {
      return defaultStyles;
    }
  };

  const shouldDisplayOtherInput =
    experience.includes("other") || experience.includes("others");

  return (
    <Box sx={container}>
      <Box sx={leftInnerContainer}>
        <Box
          sx={{
            ...leftInnerContainer,
            width: deviceType === "mobile" ? "100%" : "80%",
            flex: 1,
          }}
        >
          <Typography sx={title}>{messages.feedbackMessage}</Typography>
          <Box sx={innerContainer}>
            {smiley.isHappySmileyClicked ? (
              <Button sx={button} onClick={handleHappyClick}>
                <img src={happyClick} alt="happyClick" />
              </Button>
            ) : (
              <Button sx={button} onClick={handleHappyClick}>
                <img src={happy} alt="happy" />
              </Button>
            )}
            {smiley.isSadSmileyClicked ? (
              <Button sx={button} onClick={handleSadClick}>
                <img src={sadClick} alt="sadClick" />
              </Button>
            ) : (
              <Button sx={button} onClick={handleSadClick}>
                <img src={sad} alt="sad" />
              </Button>
            )}
          </Box>
          {(smiley.isHappySmileyClicked || smiley.isSadSmileyClicked) && (
            <Box
              sx={{
                ...leftInnerContainer,
                width: deviceType === "mobile" ? "100%" : "80%",
                marginBottom: isMobile
                  ? deviceType !== "tablet"
                    ? "100px"
                    : "120px"
                  : "80px",
              }}
            >
              <Typography sx={message}>
                {smiley.isHappySmileyClicked
                  ? messages.likeIt
                  : messages.didntLikeIt}
              </Typography>
              <Box
                sx={{
                  ...innerContainer,
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                {experienceOptions.map((option) => (
                  <FormControlLabel
                    key={option.value}
                    value={experience.includes(option.value)}
                    control={
                      <BpCheckbox
                        checked={experience.some((exp) => exp === option.value)}
                      />
                    }
                    label={option.label}
                    sx={experienceStyling(option.value)}
                    onChange={(e) => {
                      const target = e.target as HTMLInputElement;
                      const checkedExperience = [...experience];
                      if (target.checked) {
                        checkedExperience.push(option.value);
                      } else {
                        const index = checkedExperience.indexOf(option.value);
                        if (index > -1) {
                          checkedExperience.splice(index, 1);
                        }
                      }
                      onExperienceChange(checkedExperience);
                    }}
                  />
                ))}
                {shouldDisplayOtherInput && (
                  <>
                    <Typography sx={subTitle}>{messages.howYouFeel}</Typography>
                    <TextField
                      id="outlined-basic"
                      placeholder={messages.enterThoughts}
                      variant="outlined"
                      value={experienceFeedback}
                      onChange={(e) => {
                        onExperienceFeedbackChange(e.target.value);
                      }}
                      sx={textBox}
                    />
                  </>
                )}
                <Typography sx={messageThoughts}>
                  {messages.shareExperience}
                </Typography>
                <TextField
                  id="outlined-basic"
                  placeholder={messages.enterThoughts}
                  variant="outlined"
                  value={thoughts}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    const isSpaceAllowed = thoughts !== "" || inputValue === ""; 
                    const alphabetWithSpaceRegex = /^[a-zA-Z]*$/; 
                    if (isSpaceAllowed || alphabetWithSpaceRegex.test(inputValue)) {
                    onThoughtChange(e.target.value);
                  }
                  }}
                  sx={fieldBoxThought}
                  multiline
                  rows={4}
                />
              </Box>
              <Box sx={innerContainer}>
                <Button
                  sx={
                    enableContinueButton
                      ? continueButton
                      : disableContinueButton
                  }
                  disabled={!enableContinueButton}
                  onClick={handleContinueClick}
                >
                  {messages.continue}
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};
