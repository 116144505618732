export const messages: Record<string, string> = {
  onboardingMessage: "Please use a mobile phone for a ",
  betterExperience: "better experience",
  welcomeMessage: "Welcome to the Quilt content testing program!",
  aim: "The aim of this test is to explore how various types of content influence your mood and overall well-being.",
  engaging: "Quilt is dedicated to crafting and delivering engaging content",
  content:
    "The content aims to positively impact your emotional state in real-time when you need it most",
  feedback:
    "Your feedback is invaluable in helping us refine our offerings to better serve your needs. Thank you for being part of this journey with us!",
  start: "Let’s start",
  howOldAreYou: "How old are you?",
  enterAge: "Enter your age",
  enterName: "Enter your name",
  back: "Back",
  continue: "Continue",
  whatsYourgender: "What’s your gender?",
  whereAreYouLocated: "Where are you located?",
  howAreYouFeeling: "How are you feeling?",
  pleaseWait: "Please wait...",
  loadingContent: "We're loading content for you",
  congratulations: "Congratulations! You've explored it all!",
  thankyouContent:
    "Be sure to check back later for new content. Thank you for your valuable participation in shaping our platform!",
  feedbackExperience:
    "Click the button below to provide feedback on your experience.",
  shareFeedback: "Share feedback",
  valueInput: "We value your input!",
  overAllExperience:
    "Feel free to share any thoughts, suggestions, or comments you have about your overall experience here",
  enterFeedback: "Enter your feedback",
  healthCondition: "Do you have a mental health condition?",
  dontWantToAnswer: "I don’t want to answer",
  enterEmail: "Enter your email address (optional)",
  aboutUs: "How did you hear about us?",
  stayLoop: "Stay in the loop!",
  stayLoopContent:
    "Share your email address below to be the first to know about exciting developments and new content",
  submitFeedback: "Submit feedback",
  thankNewContent: "Share your feedback!",
  thanksTitle: "Thank you for sharing your feedback with us!",
  thankYou: "Congratulations! You've explored it all!",
  newContent:
    "Eager to keep playing? Hit the button below to go through the content again!",
  oldContent: "Eager to keep playing? Hit the button below to get new content!",
  replayNewContent:
    "After you share your thoughts, you can discover new experiences waiting for you or check back later for new content. ",
  newContentThanks:
    "Thank you for your valuable participation in shaping our platform!",
  replayOldContent:
    "Be sure to check back later for new content. Thank you for your valuable participation in shaping our platform!",
  oldContentThanks:
    "Click the button below to provide feedback on your experience.",
  feedbackMessage: "Let us know how you felt about the last experience",
  likeIt: "Tell us why you liked it",
  didntLikeIt: "Tell us why you didn't like it",
  howYouFeel: "Please tell us how you feel",
  enterThoughts: "Enter your thoughts",
  reflectMeditation: "Reflect after meditation: how do you feel?",
  sleepStory: "How did you feel after listening to the sleep story?",
  lastGame: "How did you feel after the last experience?",
  location: "Please enter your location",
  locationPlaceholder: "Enter your location",
  startMessage: "Start",
  shareExperience: "Share your overall experience",
};

export const localStorageLiterals: Record<string, string> = {
  authDetails: "authData",
  registerUser: "registerUser",
  userId: "userId",
  submitFeedback: "submitFeedback",
  dataIndex: "dataIndex",
  contentData: "contentData",
  content: "content",
  noContent: "noContent",
  nextDataIndex: "nextDataIndex",
  condition: "condition",
  experience: "experience",
  bulkContent: "bulkContent",
};
