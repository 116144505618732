import { useNavigate } from "react-router-dom";
import { ThankYouComponent } from "../../components/EndPage/ThankYou";
import { useEffect, useState } from "react";
import { localStorageLiterals } from "../../Constants";

export const ThankYouContainer: React.FC = () => {
  const navigate = useNavigate();
  const [isNewContent, setIsNewContent] = useState(true);

  useEffect(() => {
    const storedNoContent = localStorage.getItem(
      localStorageLiterals.noContent
    );
    if (storedNoContent) {
      setIsNewContent(storedNoContent === "false" ? false : true);
    }
  }, []);

  const handleNextButtonClick = (isNewContent: boolean) => {
    if (isNewContent) {
      localStorage.setItem(localStorageLiterals.noContent, true.toString());
      navigate("/content");
    } else {
      localStorage.clear();
      navigate("/home");
    }
  };

  return (
    <ThankYouComponent
      isNewContent={isNewContent}
      handleReplayClick={handleNextButtonClick}
    />
  );
};
