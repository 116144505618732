import { Box, CircularProgress, Theme, Typography } from "@mui/material";
import { isMobile, deviceType } from "react-device-detect";
import { SxProps } from "@mui/system";
import { messages } from "../Constants";

const container: SxProps<Theme> = {
  width: "100vw",
  height: isMobile ? "auto" : "100vh",
  minHeight: isMobile ? "100vh" : "auto",
  background: "#FFF",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  boxSizing: "border-box",
  overflowY: "auto",
  color: "#4D2D66",
};

const title: SxProps<Theme> = {
  fontfamily: "DM Sans",
  fontSize: isMobile && deviceType === "isMobileOnly" ? "16px" : "20px",
  color: "#4D2D66",
  lineHeight: "26.04px",
};

const message: SxProps<Theme> = {
  fontFamily: "DM Sans",
  fontSize: isMobile ? (deviceType === "tablet" ? "16px" : "14px") : "16px",
  color: "#4D2D66",
  lineHeight: isMobile ? (deviceType === "tablet" ? "20px" : "18px") : "22px",
  padding: "10px",
};

export const LoaderComponent: React.FC = () => {
  return (
    <Box sx={container}>
      <CircularProgress size="25px" sx={{ color: "#4D2D66" }} />
      <Typography sx={title}>{messages.pleaseWait}</Typography>
      <Typography sx={message}>{messages.loadingContent}</Typography>
    </Box>
  );
};
