import { Box, Theme, Typography } from "@mui/material";
import { isMobile, deviceType } from "react-device-detect";
import Phone from "../asset/iphone.svg";
import { SxProps } from "@mui/system";
import { messages } from "../Constants";

const container: SxProps<Theme> = {
  width: "100vw",
  height: isMobile ? "auto" : "100vh",
  minHeight: isMobile ? "100vh" : "auto",
  background: "#FFF",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  boxSizing: "border-box",
  overflowY: "auto",
  color: "#4D2D66",
};

const phoneImage = {
  width: isMobile && deviceType === "isMobileOnly" ? "36px" : "48px",
  height: isMobile && deviceType === "isMobileOnly" ? "36px" : "48px",
  paddingBottom: "50px",
};

const message: SxProps<Theme> = {
  fontfamily: "DM Sans",
  fontSize: isMobile && deviceType === "isMobileOnly" ? "16px" : "20px",
  color: "#4D2D66",
  lineHeight: "26.04px",
};

export const OnboardingComponent: React.FC = () => {
  return (
    <Box sx={container}>
      <img style={phoneImage} src={Phone} alt="iphone" />
      <Typography sx={message}>{messages.onboardingMessage}</Typography>
      <Typography sx={message}>{messages.betterExperience}</Typography>
    </Box>
  );
};
