import groupImage from "../../asset/GroupLogo.svg";
import charcoDeadline from "../../asset/CharcoEducation.svg";
import { Box, Button, TextField, Theme, Typography } from "@mui/material";
import { isMobile, deviceType } from "react-device-detect";
import { SxProps } from "@mui/system";
import { messages } from "../../Constants";
import Slider from "rc-slider";
import selectImage from "../../asset/Select.svg";

const container: SxProps<Theme> = {
  width: "100vw",
  height: isMobile && deviceType !== "tablet" ? "auto" : "100vh",
  minHeight: isMobile && deviceType !== "tablet" ? "100vh" : "auto",
  background: "#FFF",
  display: "flex",
  flex: 1,
  flexDirection: isMobile && deviceType !== "tablet" ? "column-reverse" : "row",
  boxSizing: "border-box",
  overflowY: "auto",
  justifyContent: "space-between",
  color: "#4D2D66",
};

const rightsideContainer: SxProps<Theme> = {
  display: "flex",
  width: isMobile ? "100%" : "25%",
  minHeight: isMobile ? "15vh" : "auto",
  backgroundColor: "#F0E4EE",
  alignItems: "center",
  justifyContent: "center",
};

const leftsideContainer: SxProps<Theme> = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  alignContent: "center",
  alignItems: "center",
  justifyContent: "center",
};

const leftInnerContainer: SxProps<Theme> = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  alignContent: "center",
  alignItems: "center",
  justifyContent: "center",
  width: isMobile ? "80%" : "40%",
};

const charcoImage = {
  display: "flex",
  flex: 1,
  width: isMobile ? "15vh" : "318px",
  height: isMobile ? "15vh" : "238px",
  padding: "10px",
  paddingTop: "40px",
  alignSelf: "center",
};

const logoImage = {
  width: "54px",
  height: "54px",
  paddingBottom: "25px",
};

const title: SxProps<Theme> = {
  fontFamily: "DM Sans Light",
  fontSize: isMobile ? (deviceType === "tablet" ? "28px" : "24px") : "32px",
  color: "#4D2D66",
  lineHeight: isMobile
    ? deviceType === "tablet"
      ? "35px"
      : "30px"
    : "41.66px",
  textAlign: "center",
  marginBottom: "15px",
};

const innerContainer: SxProps<Theme> = {
  display: "flex",
  alignContent: "space-between",
  width: "100%",
  paddingBottom: "5px",
  textAlign: "left",
  flexDirection: deviceType === "mobile" ? "column-reverse" : "row",
};

const continueButton: SxProps<Theme> = {
  fontFamily: "DM Sans",
  textTransform: "none",
  height: "50px",
  width: "100%",
  alignSelf: "center",
  backgroundColor: "#BF50B2",
  textAlign: "center",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "14px",
  letterSpacing: "1.5px",
  border: "none",
  borderRadius: "56px",
  outline: "none",
  marginRight: deviceType === "mobile" ? 0 : "20px",
  marginLeft: deviceType === "mobile" ? 0 : "20px",
  color: "white",
  ":hover": {
    backgroundColor: "#BF50B2",
    color: "white",
  },
};

const disableContinueButton: SxProps<Theme> = {
  fontFamily: "DM Sans",
  textTransform: "none",
  height: "50px",
  width: "100%",
  alignSelf: "center",
  backgroundColor: "rgba(191, 80, 178, 0.3)",
  textAlign: "center",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "14px",
  letterSpacing: "1.5px",
  border: "none",
  borderRadius: "56px",
  outline: "none",
  color: "white",
  marginRight: deviceType === "mobile" ? 0 : "20px",
  marginLeft: deviceType === "mobile" ? 0 : "20px",
  ":hover": {
    backgroundColor: "rgba(191, 80, 178, 0.3)",
    color: "white",
  },
};

const backButton: SxProps<Theme> = {
  fontFamily: "DM Sans",
  textTransform: "none",
  height: "50px",
  width: "100%",
  alignSelf: "center",
  backgroundColor: "#FFFFFF",
  textAlign: "center",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "14px",
  letterSpacing: "1.5px",
  border: "1px solid #000", // Specify both border style and color
  borderRadius: "56px",
  outline: "none",
  color: "#000",
  marginTop: deviceType === "mobile" ? "15px" : 0,
  marginRight: deviceType === "mobile" ? 0 : "20px",
  marginLeft: deviceType === "mobile" ? 0 : "20px",
  ":hover": {
    backgroundColor: "#FFFFFF",
    color: "#000",
  },
};

const fieldBox: SxProps<Theme> = {
  width: "95%",
  marginBottom: "30px",
  marginTop: "16px",
  "& .MuiOutlinedInput-root": {
    borderRadius: "32px",
    background: "#FFFFFF",
    padding: "10px",
  },
  "& .MuiOutlinedInput-input": {
    background: "#FFFFFF",
    padding: "10px",
  },
  "& .MuiOutlinedInput-root:hover, & .Mui-focused .MuiOutlinedInput-root.Mui-focused":
    {
      borderColor: "#CFCFCF",
      padding: "10px",
    },
};

const dataList: SxProps<Theme> = {
  marginBottom: "5px",
  display: "flex",
  width: "85%",
  flexDirection: "column",
  alignItems: "center",
  marginTop: isMobile ? (deviceType !== "tablet" ? "30px" : "120px") : "30px",
};

const customSlider = {
  display: "flex",
  width: "100%",
};

const trackStyles: React.CSSProperties = {
  backgroundColor: "#BF50B2",
  height: "3px",
};

const handleStyles: React.CSSProperties = {
  border: "4px solid #BF50B2",
  backgroundColor: "#FFFFFF",
  height: "16px",
  width: "16px",
  boxShadow: "none",
  position: "absolute",
  top: "2px",
  zIndex: 999,
};

const railStyles: React.CSSProperties = {
  height: "3px",
  backgroundColor: "#CFCFCF",
};

const activeHandleStyle: React.CSSProperties = {
  position: "absolute",
  top: "-7px",
  border: "0px solid #CFCFCF",
  backgroundImage: `url(${selectImage})`,
  height: "16px",
  width: "16px",
  boxShadow: "none",
};

const dotStyle: React.CSSProperties = {
  position: "absolute",
  top: "-7px",
  border: "4px solid #CFCFCF",
  backgroundColor: "white",
  height: "16px",
  width: "16px",
  boxShadow: "none",
};

const markStyle: React.CSSProperties = {
  fontSize: "14px",
  color: "black",
  position: "absolute",
  top: "0px", // Adjust this value based on your preference for vertical positioning
  transform: "translateX(-50%)", // Center the mark horizontally
  whiteSpace: "nowrap", // Prevent line breaks
};

const message: SxProps<Theme> = {
  fontFamily: "DM Sans",
  fontSize: isMobile ? (deviceType === "tablet" ? "16px" : "14px") : "16px",
  color: "#4D2D66",
  lineHeight: isMobile ? (deviceType === "tablet" ? "20px" : "18px") : "22px",
  padding: "10px",
};

type ExperienceComponentProps = {
  enableContinueButton: boolean;
  hasCondition: boolean;
  selectedRange: number;
  feedback?: string;
  handleRangeClick(selectedRange: number | number[]): void;
  onFeedbackChange(feedback: string): void;
  handleBackClick(): void;
  handleContinueClick(hasCondition: boolean): void;
};

export const ExperienceComponent: React.FC<ExperienceComponentProps> = ({
  enableContinueButton,
  hasCondition,
  feedback,
  selectedRange,
  handleRangeClick,
  onFeedbackChange,
  handleBackClick,
  handleContinueClick,
}) => {
  return (
    <Box sx={container}>
      <Box sx={leftsideContainer}>
        <Box sx={leftInnerContainer}>
          <Box sx={dataList}>
            {!hasCondition && (
              <Slider
                min={1}
                max={2}
                trackStyle={trackStyles}
                handleStyle={handleStyles}
                railStyle={railStyles}
                activeDotStyle={activeHandleStyle}
                dotStyle={dotStyle}
                marks={{
                  1: <span style={markStyle}>Experience</span>,
                  2: <span style={markStyle}>Condition</span>,
                }}
                value={selectedRange}
                defaultValue={1}
                style={customSlider}
                onChange={handleRangeClick}
              />
            )}
          </Box>
          <Box
            sx={{
              ...leftInnerContainer,
              width: deviceType === "mobile" ? "100%" : "80%",
              flex: 1,
              marginBottom: isMobile
                ? deviceType !== "tablet"
                  ? "100px"
                  : "120px"
                : "80px",
              marginTop: isMobile ? (deviceType !== "tablet" ? "50px" : 0) : 0,
            }}
          >
            <img style={logoImage} src={groupImage} alt="iphone" />
            <Typography sx={title}>{messages.valueInput}</Typography>
            <Typography sx={{ ...message, textAlign: "center" }}>
              {messages.overAllExperience}
            </Typography>
            <TextField
              id="outlined-basic"
              placeholder={messages.enterFeedback}
              variant="outlined"
              value={feedback}
              onChange={(e) => {
                const inputValue = e.target.value;
                const isSpaceAllowed = feedback !== "" || inputValue === ""; 
                const alphabetWithSpaceRegex = /^[a-zA-Z]*$/; 
                if (isSpaceAllowed || alphabetWithSpaceRegex.test(inputValue)) {
                onFeedbackChange(e.target.value);
                }
              }}
              sx={fieldBox}
              multiline
              rows={4}
            />
            <Box sx={innerContainer}>
              <Button sx={backButton} onClick={handleBackClick}>
                {messages.back}
              </Button>
              <Button
                sx={
                  enableContinueButton ? continueButton : disableContinueButton
                }
                disabled={!enableContinueButton}
                onClick={() => handleContinueClick(hasCondition)}
              >
                {messages.continue}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      {!isMobile && (
        <Box sx={rightsideContainer}>
          <img style={charcoImage} src={charcoDeadline} alt="iphone" />
        </Box>
      )}
    </Box>
  );
};
