export const bulkFeedbackApiPath = "/api/web-page/submit-bulk-feedback";

export type BulkUserResponseDTO = {
  status: number;
  message: string;
};

export type BulkUserRequestDTO = {
  comment: string;
  userId: string;
  contentIds: string[];
  isFinalContent: boolean;
  condition?: string;
};
