import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Details,
  RegisterUserContext,
  RegisterUserContextProps,
} from "../common/RegisterUserContext";
import { NameComponent } from "../components/Name";
import { localStorageLiterals } from "../Constants";

export const NameContainer: React.FC = () => {
  const navigate = useNavigate();
  const [enableContinueButton, setEnableContinueButton] = useState(false);
  const [selectedRange, setSelectedRange] = useState<number | number[]>(1);

  const { registerUserDetails, setRegisterUserDetails } = useContext(
    RegisterUserContext
  ) as RegisterUserContextProps;

  const onNameChange = (value: string) => {
    setRegisterUserDetails({ ...registerUserDetails, name: value });
  };

  const handleBackClick = () => {
    navigate("/home");
  };

  const handleContinueClick = () => {
    const registerUserData: Details = {
      ...registerUserDetails,
      name: registerUserDetails.name,
    };
    localStorage.setItem(
      localStorageLiterals.registerUser,
      JSON.stringify(registerUserData)
    );
    navigate("/age");
  };

  useEffect(() => {
    if (registerUserDetails.name.trim() !== "") {
      setEnableContinueButton(true);
    } else {
      setEnableContinueButton(false);
    }
  }, [registerUserDetails.name]);  

  return (
    <NameComponent
      enableContinueButton={enableContinueButton}
      name={registerUserDetails.name}
      selectedRange={+selectedRange}
      handleRangeClick={setSelectedRange}
      onNameChange={onNameChange}
      handleBackClick={handleBackClick}
      handleContinueClick={handleContinueClick}
    />
  );
};
