import React from "react";
import { Box, Button, Theme, Typography } from "@mui/material";
import { isMobile, deviceType } from "react-device-detect";
import { SxProps } from "@mui/system";
import { messages } from "../../Constants";
const container: SxProps<Theme> = {
  width: "100vw",
  height: isMobile ? "auto" : "100vh",
  minHeight: isMobile ? "100vh" : "auto",
  background: "#FFF",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  boxSizing: "border-box",
  overflowY: "auto",
  color: "#4D2D66",
};

const leftInnerContainer: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  alignContent: "center",
  alignItems: "center",
  justifyContent: "center",
  width: isMobile ? "80%" : "40%",
};

const title: SxProps<Theme> = {
  fontFamily: "DM Sans Light",
  fontSize: isMobile ? (deviceType === "tablet" ? "28px" : "24px") : "32px",
  color: "#4D2D66",
  lineHeight: isMobile
    ? deviceType === "tablet"
      ? "35px"
      : "30px"
    : "41.66px",
  textAlign: "center",
  marginBottom: "15px",
};

const customSlider = {
  display: "flex",
  width: "100%",
};

const optionsContainer: SxProps<Theme> = {
  display: "flex",
  flexDirection: "row",
  width: "100%",
  justifyContent: "space-between",
  marginBottom: !isMobile ? "15px" : "0",
};

const optionStyle: React.CSSProperties = {
  textAlign: "center",
  cursor: "pointer",
  borderBottom: "2px solid transparent",
};

const innerContainer: SxProps<Theme> = {
  marginTop: "20px",
  display: "flex",
  alignContent: "space-between",
  width: "100%",
  paddingBottom: "5px",
  textAlign: "left",
  flexDirection: deviceType === "mobile" ? "column-reverse" : "row",
};

const continueButton: SxProps<Theme> = {
  fontFamily: "DM Sans",
  textTransform: "none",
  height: "50px",
  width: "100%",
  alignSelf: "center",
  backgroundColor: "#BF50B2",
  textAlign: "center",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "14px",
  letterSpacing: "1.5px",
  border: "none",
  borderRadius: "56px",
  outline: "none",
  marginRight: deviceType === "mobile" ? 0 : "20px",
  marginLeft: deviceType === "mobile" ? 0 : "20px",
  color: "white",
  ":hover": {
    backgroundColor: "#BF50B2",
    color: "white",
  },
};

const disableContinueButton: SxProps<Theme> = {
  fontFamily: "DM Sans",
  textTransform: "none",
  height: "50px",
  width: "100%",
  alignSelf: "center",
  backgroundColor: "rgba(191, 80, 178, 0.3)",
  textAlign: "center",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "14px",
  letterSpacing: "1.5px",
  border: "none",
  borderRadius: "56px",
  outline: "none",
  color: "white",
  marginRight: deviceType === "mobile" ? 0 : "20px",
  marginLeft: deviceType === "mobile" ? 0 : "20px",
  ":hover": {
    backgroundColor: "rgba(191, 80, 178, 0.3)",
    color: "white",
  },
};

const backButton: SxProps<Theme> = {
  fontFamily: "DM Sans",
  textTransform: "none",
  height: "50px",
  width: "100%",
  alignSelf: "center",
  backgroundColor: "#FFFFFF",
  textAlign: "center",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "14px",
  letterSpacing: "1.5px",
  border: "1px solid #000", // Specify both border style and color
  borderRadius: "56px",
  outline: "none",
  color: "#000",
  marginTop: deviceType === "mobile" ? "15px" : 0,
  marginRight: deviceType === "mobile" ? 0 : "20px",
  marginLeft: deviceType === "mobile" ? 0 : "20px",
  ":hover": {
    backgroundColor: "#FFFFFF",
    color: "#000",
  },
};

export type MeditationFeedbackProps = {
  enableContinueButton: boolean;
  selectedRange: string;
  handleRangeClick(selectedRange: string): void;
  handleBackClick(): void;
  handleContinueClick(): void;
};

export const MeditationFeedbackComponent: React.FC<MeditationFeedbackProps> = ({
  selectedRange,
  enableContinueButton,
  handleBackClick,
  handleContinueClick,
  handleRangeClick,
}) => {
  return (
    <Box sx={container}>
      <Box sx={leftInnerContainer}>
        <Box
          sx={{
            ...leftInnerContainer,
            width: deviceType === "mobile" ? "100%" : "80%",
            flex: 1,
          }}
        >
          <Typography sx={title}>{messages.reflectMeditation}</Typography>
          <input
            type="range"
            min="-3"
            max="3"
            value={selectedRange || "0"}
            id="fader"
            step="1"
            list="rateFeeling"
            style={{
              ...customSlider,
              color: selectedRange
                ? "#BF50B2 !important"
                : "#909090 !important",
            }}
            onChange={(event) => handleRangeClick(event.target.value)}
            disabled={!selectedRange}
          />
          <Box sx={optionsContainer}>
            <div style={optionStyle} onClick={() => handleRangeClick("-3")}>
              Anxious/Tense
            </div>
            <div
              style={optionStyle}
              onClick={() => handleRangeClick("-2")}
            ></div>
            <div
              style={optionStyle}
              onClick={() => handleRangeClick("-1")}
            ></div>
            <div
              style={optionStyle}
              onClick={() => handleRangeClick("0")}
            ></div>
            <div
              style={optionStyle}
              onClick={() => handleRangeClick("1")}
            ></div>
            <div
              style={optionStyle}
              onClick={() => handleRangeClick("2")}
            ></div>
            <div style={optionStyle} onClick={() => handleRangeClick("3")}>
              Calm/Relaxed
            </div>
          </Box>
          <Box sx={innerContainer}>
            <Button sx={backButton} onClick={handleBackClick}>
              {messages.back}
            </Button>
            <Button
              sx={enableContinueButton ? continueButton : disableContinueButton}
              disabled={!enableContinueButton}
              onClick={handleContinueClick}
            >
              {messages.submitFeedback}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
