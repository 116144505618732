import { PropsWithChildren, createContext, useState } from "react";
import { ContentResponseDTO } from "../dto/content";

export type ContentContextProps = {
  contentData: any[];
  dataIndex: number;
  setDataIndex(dataIndex: number): void;
  setContentData: (newContent: any[]) => void;
};

export const ContentContext = createContext<ContentContextProps | null>(null);

export const ContentContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const [contentData, setContentData] = useState<any[]>([]);
  const [dataIndex, setDataIndex] = useState(0);
  return (
    <ContentContext.Provider
      value={{ contentData, dataIndex, setDataIndex, setContentData }}
    >
      {children}
    </ContentContext.Provider>
  );
};
