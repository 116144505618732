import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import CancelSharpIcon from "@mui/icons-material/CancelSharp";
import "./ResponsiveVideo.css";
import Feedback from "./Feedback";
import { REACT_APP_IS_GAME_CODE } from "../../env";
import { localStorageLiterals } from "../../Constants";
import { useNavigate } from "react-router-dom";

const ResponsiveVideo = ({ videoUrl }: any) => {
  const navigate = useNavigate();
  const [isClosedButtonClicked, setIsClosedButtonClicked] = useState(false);

  const onClose = () => {
    setIsClosedButtonClicked(true);
  };

  let userId = localStorage.getItem("userId");
  let storedContentData: any = localStorage.getItem(
    localStorageLiterals?.contentData
  );

  if (storedContentData) {
    storedContentData = JSON.parse(storedContentData);
  }

  let dataIndexString = localStorage.getItem(localStorageLiterals?.dataIndex);

  let dataIndex: any;

  if (dataIndexString) {
    dataIndex = parseInt(dataIndexString);
  }

  let contentId: any = storedContentData[dataIndex].id;

  useEffect(() => {
    if (isClosedButtonClicked) {
      if (REACT_APP_IS_GAME_CODE) {
        const topWindow = window.top;
        if (topWindow) {
          topWindow.location.href = `/feedback?userId=${userId}&contentId=${contentId}`;
        }
      } else {
        navigate(`/feedback?userId=${userId}&contentId=${contentId}`);
      }
    }
  }, [isClosedButtonClicked, REACT_APP_IS_GAME_CODE]);

  return (
    <div className="parent-container-video">
      <IconButton className="close-button-video" onClick={onClose}>
        <CancelSharpIcon sx={{ color: "black", fontSize: 30 }} />
      </IconButton>
      <div className="responsive-video-container">
        {/* <iframe
          title="Responsive Video"
          src={videoUrl}
          className="responsive-video-iframe"
          allowFullScreen
        ></iframe> */}
        <video controls className="responsive-video-iframe">
          <source src={videoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};

export default ResponsiveVideo;
