import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DiscoveryComponent } from "../../components/EndPage/Discovery";
import { localStorageLiterals } from "../../Constants";

export const DiscoveryContainer: React.FC = () => {
  const navigate = useNavigate();
  const [aboutUs, setAboutUs] = useState("");
  const [emailId, setEmailId] = useState("");
  const [enableContinueButton, setEnableContinueButton] = useState(false);
  const [selectedRange, setSelectedRange] = useState<number | number[]>(3);
  const [hasCondition, setHasCondition] = useState(false);

  useEffect(() => {
    const storedCondition = localStorage.getItem(
      localStorageLiterals.condition
    );
    if (storedCondition) {
      setHasCondition(storedCondition === "true" ? true : false);
    }
  }, []);

  const handleBackClick = (hasCondition: boolean) => {
    if (!hasCondition) {
      navigate("/condition");
    } else {
      navigate("/experience");
    }
  };
  const handleContinueClick = () => {
    localStorage.setItem(localStorageLiterals.condition, true.toString());
    navigate("/thankyou");
  };

  useEffect(() => {
    if (aboutUs) {
      setEnableContinueButton(true);
    } else {
      setEnableContinueButton(false);
    }
  }, [aboutUs]);
  return (
    <DiscoveryComponent
      enableContinueButton={enableContinueButton}
      hasCondition={hasCondition}
      emailId={emailId}
      aboutUs={aboutUs}
      selectedRange={+selectedRange}
      handleRangeClick={setSelectedRange}
      onAboutUsChange={setAboutUs}
      handleEmailIdClick={setEmailId}
      handleBackClick={handleBackClick}
      handleContinueClick={handleContinueClick}
      aboutUsOptions={[
        "Friends / Family",
        "Forum / Online Community",
        "Advertisement",
        "Other",
      ]}
    />
  );
};
