import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ExperienceComponent } from "../../components/EndPage/Experience";
import { localStorageLiterals } from "../../Constants";
import { useAsync } from "../../hooks/use-async";
import {
  BulkUserRequestDTO,
  BulkUserResponseDTO,
  bulkFeedbackApiPath,
} from "../../dto/bulkFeedback";
import { AxiosResponse } from "axios";
import { ApiClientContext } from "../../context/ApiClientContext";

export const ExperienceContainer: React.FC = () => {
  const apiClient = useContext(ApiClientContext)!;
  const navigate = useNavigate();
  const [feedback, setFeedback] = useState("");
  const [enableContinueButton, setEnableContinueButton] = useState(false);
  const [selectedRange, setSelectedRange] = useState<number | number[]>(1);
  const [hasCondition, setHasCondition] = useState(false);
  const [contentIds, setContentIds] = useState<string[]>([]);
  const [isContinue, setIsContinue] = useState(false);
  const [isLast, setIsLast] = useState(false);
  const [userId, setUserId] = useState<string | null>(null);

  const [result, setResult] = useAsync<BulkUserResponseDTO>({
    fn: async (data: BulkUserRequestDTO) => {
      if (data) {
        const result = await apiClient.post<
          BulkUserRequestDTO,
          AxiosResponse<BulkUserRequestDTO, any>,
          any
        >(bulkFeedbackApiPath, JSON.stringify(data), {
          headers: {
            "Content-Type": "application/json",
          },
        });
        return result.data as any;
      }
    },
  });

  useEffect(() => {
    const storedCondition = localStorage.getItem(
      localStorageLiterals.condition
    );
    const storedFeedback = localStorage.getItem(
      localStorageLiterals.experience
    );
    const storedContentIds = localStorage.getItem(
      localStorageLiterals.bulkContent
    );
    const storedNoContent = localStorage.getItem("isLast");
    const storedUserId = localStorage.getItem(localStorageLiterals.userId);
    if (storedUserId) {
      setUserId(storedUserId);
    }
    if (storedNoContent) {
      setIsLast(storedNoContent === "true" ? true : false);
    }
    if (storedContentIds) {
      const parsedIds = JSON.parse(storedContentIds);
      setContentIds(parsedIds);
    }
    if (storedFeedback) {
      setFeedback(storedFeedback);
    }
    if (storedCondition) {
      setHasCondition(storedCondition === "true" ? true : false);
    }
  }, []);

  const handleBackClick = () => {
    navigate("/endpage");
  };

  useEffect(() => {
    (async () => {
      if (isContinue && enableContinueButton && userId) {
        const dataValue: BulkUserRequestDTO = {
          comment: feedback,
          contentIds: contentIds,
          isFinalContent: isLast,
          userId: userId,
        };
        await setResult(dataValue);
      }
      setIsContinue(false);
    })();
  }, [isContinue, enableContinueButton, userId, feedback, contentIds, isLast]);

  useEffect(() => {
    if (result.result && result.result.status === 200) {
      localStorage.removeItem(localStorageLiterals.bulkContent);
      navigate("/thankyou");
    }
  }, [result.result]);

  const handleContinueClick = (hasCondition: boolean) => {
    if (!hasCondition) {
      localStorage.setItem(localStorageLiterals.experience, feedback);
      navigate("/condition");
    } else {
      setIsContinue(true);
    }
  };
  useEffect(() => {
    if (feedback) {
      setEnableContinueButton(true);
    } else {
      setEnableContinueButton(false);
    }
  }, [feedback]);
  return (
    <ExperienceComponent
      hasCondition={hasCondition}
      enableContinueButton={enableContinueButton}
      feedback={feedback}
      selectedRange={+selectedRange}
      handleRangeClick={setSelectedRange}
      onFeedbackChange={setFeedback}
      handleBackClick={handleBackClick}
      handleContinueClick={handleContinueClick}
    />
  );
};
