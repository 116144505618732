import groupImage from "../asset/GroupLogo.svg";
import charcoPicture from "../asset/CharcoPicture.svg";
import smiley from "../asset/Smiley.svg";
import spark from "../asset/Spark.svg";
import { OnboardingComponent } from "./Onboarding";
import { Box, Button, Theme, Typography } from "@mui/material";
import { isMobile, deviceType } from "react-device-detect";
import { SxProps } from "@mui/system";
import { messages } from "../Constants";

const container: SxProps<Theme> = {
  width: "100vw",
  height: isMobile && deviceType !== "tablet" ? "auto" : "100vh",
  minHeight: isMobile && deviceType !== "tablet" ? "100vh" : "auto",
  background: "#FFF",
  display: "flex",
  flex: 1,
  flexDirection: isMobile && deviceType !== "tablet" ? "column-reverse" : "row",
  boxSizing: "border-box",
  overflowY: "auto",
  justifyContent: "space-between",
  color: "#4D2D66",
};

const rightsideContainer: SxProps<Theme> = {
  display: "flex",
  width: isMobile && deviceType !== "tablet" ? "100%" : "25%",
  minHeight: isMobile && deviceType !== "tablet" ? "15vh" : "auto",
  backgroundColor: "#F0E4EE",
  alignItems: "center",
  justifyContent: "center",
};

const leftsideContainer: SxProps<Theme> = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  alignContent: "center",
  alignItems: "center",
  justifyContent: "center",
};

const leftInnerContainer: SxProps<Theme> = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  alignContent: "center",
  alignItems: "center",
  justifyContent: "center",
  width: isMobile ? "80%" : "40%",
};

const charcoImage = {
  display: "flex",
  flex: 1,
  width: isMobile ? "15vh" : "318px",
  height: isMobile ? "15vh" : "238px",
  padding: "10px",
  paddingTop: "40px",
  alignSelf: "center",
};

const logoImage = {
  width: "54px",
  height: "54px",
  paddingBottom: "25px",
};

const smileyImage = {
  width: "54px",
  height: "54px",
};

const title: SxProps<Theme> = {
  fontFamily: "DM Sans Light",
  fontSize: isMobile ? (deviceType === "tablet" ? "28px" : "24px") : "32px",
  color: "#4D2D66",
  lineHeight: isMobile
    ? deviceType === "tablet"
      ? "35px"
      : "30px"
    : "41.66px",
  textAlign: "center",
  marginBottom: "15px",
};

const message: SxProps<Theme> = {
  fontFamily: "DM Sans",
  fontSize: isMobile ? (deviceType === "tablet" ? "16px" : "14px") : "16px",
  color: "#4D2D66",
  lineHeight: isMobile ? (deviceType === "tablet" ? "20px" : "18px") : "22px",
  padding: "10px",
};

const innerContainer: SxProps<Theme> = {
  display: "flex",
  alignContent: "space-between",
  width: "95%",
  paddingBottom: "5px",
  textAlign: "left",
};

const button: SxProps<Theme> = {
  fontFamily: "DM Sans",
  textTransform: "none",
  height: "50px",
  width: deviceType === "mobile" ? "100%" : "35%",
  alignSelf: "center",
  backgroundColor: "#BF50B2",
  textAlign: "center",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "14px",
  letterSpacing: "1.5px",
  border: "none",
  borderRadius: "56px",
  outline: "none",
  marginTop: "15px",
  color: "white",
  ":hover": {
    backgroundColor: "#BF50B2",
    color: "white",
  },
};

type HomeComponentProps = {
  showOnBoarding: boolean;
  handleNextButtonClick(): void;
};

export const HomeComponent: React.FC<HomeComponentProps> = ({
  showOnBoarding,
  handleNextButtonClick,
}) => {
  return (
    <>
      {showOnBoarding ? (
        <OnboardingComponent />
      ) : (
        <Box sx={container}>
          <Box sx={leftsideContainer}>
            <Box sx={leftInnerContainer}>
              <img style={logoImage} src={groupImage} alt="iphone" />
              <Typography sx={title}>{messages.welcomeMessage}</Typography>
              <Typography sx={{ ...message, textAlign: "center" }}>
                {messages.aim}
              </Typography>
              <Box sx={innerContainer}>
                <img style={smileyImage} src={spark} alt="sparl" />
                <Typography sx={message}>{messages.engaging}</Typography>
              </Box>
              <Box sx={innerContainer}>
                <img style={smileyImage} src={smiley} alt="smiley" />
                <Typography sx={message}>{messages.content}</Typography>
              </Box>
              <Typography sx={{ ...message, textAlign: "center" }}>
                {messages.feedback}
              </Typography>
              <Button sx={button} onClick={handleNextButtonClick}>
                {messages.start}
              </Button>
            </Box>
          </Box>
          {deviceType !== "mobile" && (
            <Box sx={rightsideContainer}>
              <img style={charcoImage} src={charcoPicture} alt="iphone" />
            </Box>
          )}
        </Box>
      )}
    </>
  );
};
