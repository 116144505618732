export const submitFeedbackApiPath = "/api/web-page/submit-feedback";

export type SubmitFeedbackResponseDTO = {
  status: number;
  message: string;
};

export type SubmitFeedbackRequestDTO = {
  like: boolean;
  comment: string;
  reaction: string;
  userId: string;
  contentId: string;
  happyScale: number;
  calmScale: number;
  energizedScale: number;
  focusedScale: number;
  alertScale: number;
  restlessScale: number;
  detailedComment?:string;
};
